import React from 'react';
import type { SVGProps } from 'react';

export function GgAdd(props: SVGProps<SVGSVGElement>) {
  return (<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M13 24C19.0751 24 24 19.0751 24 13C24 6.92487 19.0751 2 13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24ZM13.8333 12.1667H17.1667C17.6267 12.1667 18 12.54 18 13C18 13.46 17.6267 13.8333 17.1667 13.8333H13.8333V17.1667C13.8333 17.6267 13.46 18 13 18C12.5392 18 12.1667 17.6267 12.1667 17.1667V13.8333H8.83333C8.3725 13.8333 8 13.46 8 13C8 12.54 8.3725 12.1667 8.83333 12.1667H12.1667V8.83333C12.1667 8.37333 12.5392 8 13 8C13.46 8 13.8333 8.37333 13.8333 8.83333V12.1667Z"
            fill="currentColor" />
    </svg>
  );
}