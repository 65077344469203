import BaseModal from '@/components/base/baseModal';
import BaseTabs from '@/components/base/baseTabs';
import {
  CompletedOrderOrderProcessEnum,
  ImMessageTypeEnum,
} from '@/enums/businessEnum';
import CacheList from '@/pages/funds/orderHistory/index/components/CacheList';
import RechargeRecordList from '@/pages/funds/orderHistory/index/components/RechargeRecordList';
import WithdrawRecordList from '@/pages/funds/orderHistory/index/components/WithdrawRecordList';
import useUrlState from '@ahooksjs/use-url-state';
import { ScrollShadow } from '@nextui-org/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImFundingRecordsList from '../Main/ImFundingRecordsList';

/**
 * QuickPhrases 订单抽屉
 */
type IProps = {
  isOpen: boolean;
  onClose: any;
  sendMsg: any;
  groupId: number;
};
export default function (props: IProps) {
  const { isOpen, onClose, sendMsg, groupId } = props || {};
  const { t } = useTranslation();
  const sendFn = (data: any) => {
    sendMsg({
      type: ImMessageTypeEnum.ORDER,
      groupId,
      data,
    });
  };
  const fundingRecordsTabs = useMemo(()=>{
    return [
      {
        text: t('资金'),
        value: CompletedOrderOrderProcessEnum.ALL,
        component: ImFundingRecordsList,
        props: {
          sendFn,
        },
      },
      {
        text: t('充值'),
        value: CompletedOrderOrderProcessEnum.ADD,
        component: RechargeRecordList,
        props: {
          sendFn,
        },
      },
      {
        text: t('提现'),
        value: CompletedOrderOrderProcessEnum.SUB,
        component: WithdrawRecordList,
        props: {
          sendFn,
        },
      },
    ]
  },[sendFn, groupId])

  const [urlState] = useUrlState<any>({});
  const { type } = urlState || {};
  const [currentTab, setCurrentTab] = useState(
    type ?? CompletedOrderOrderProcessEnum.ALL,
  );

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}  classNames={{
      modal: '!rounded-none bg-background',
    }}>
      <div className="pt-1  text-foreground  pb-[140px] h-[60vh] overflow-y-auto max-w-[100%]  sm:h-[40vh]">
        <ScrollShadow hideScrollBar className="h-[60vh]">
          <BaseTabs
            options={fundingRecordsTabs}
            tabsPropsProps={{
              classNames: {
                tabList: 'px-4',
              },
            }}
            value={currentTab}
            onChange={setCurrentTab}
          />
          <div className="px-4 pt-4">
            {/*这里使用display大法渲染当前tab对应的component--避免重复请求 影响体验*/}
            <CacheList currentTab={currentTab} tabs={fundingRecordsTabs} />
          </div>
        </ScrollShadow>
      </div>
    </BaseModal>
  );
}
