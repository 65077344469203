import {
  CompletedOrderOrderProcessEnum,
  CompletedOrderOrderStatusEnum,
} from '@/enums/businessEnum';
import RenderUtil from '@/utils/RenderUtil';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
                  item,
                  isReplyBox = false,
                  isRight = false,
                  isReply = false,
                }: IProps) => {
  const { t } = useTranslation();
  const successColor = 'text-successColor';
  // 标题颜色
  const titleColor = useMemo(() => {
    if (isRight) {
      return 'text-[#000]';
    } else if (isReplyBox) {
      return 'text-foreground';
    } else {
      return 'text-backContrastColor';
    }
  }, [isRight, isReply]);
  // label 颜色
  const labelColor = useMemo(() => {
    if (isRight) {
      return 'text-[#000]/80';
    } else if (isReplyBox) {
      return 'text-auxiliaryTextColor';
    } else {
      return 'text-backContrastColor';
    }
  }, [isRight, isReply]);
  // value颜色
  const valueColor = useMemo(() => {
    if (isRight) {
      return 'text-[#000]';
    } else if (isReplyBox) {
      return 'text-backContrastColor';
    } else {
      return 'text-backContrastColor';
    }
  }, [isRight, isReply]);
  // 背景颜色
  const bgColor = useMemo(() => {
    if (isRight) {
      return 'bg-[#fff]';
    } else if (isReplyBox) {
      return 'bg-background';
    } else if (isReply) {
      return 'bg-backgroundAuxiliaryColor';
    } else {
      return 'bg-background';
    }
  }, [isRight, isReplyBox]);

  const showCardMap = {
    [+CompletedOrderOrderProcessEnum.ADD]: [
      {
        label: t('币种'),
        value: <span className={successColor}>{item?.coinName}</span>,
      },
      {
        label: t('进度'),
        value: (
          <>
            {item?.status === +CompletedOrderOrderStatusEnum.UnderReview && (
              <span className="text-left text-warningColor">{t('审核中')}</span>
            )}
            {item?.status ===
              +CompletedOrderOrderStatusEnum.WAITING_FOR_REVIEW && (
                <span className="text-left text-warningColor">
                {t('等待审核中')}
              </span>
              )}
            {item?.status === +CompletedOrderOrderStatusEnum.COMPLETED && (
              <span className={`text-left  ${successColor}`}>
                {t('已通过')}
              </span>
            )}
            {item?.status === +CompletedOrderOrderStatusEnum.DID_NOT_PASS && (
              <span className="text-left text-errorColor">{t('未通过')}</span>
            )}
          </>
        ),
      },
      {
        label: t('金额'),
        value: <span>{RenderUtil.FormatAmount(item?.amount, 8)}</span>,
      },
      {
        label: t('创建时间'),
        value: <span>{RenderUtil.formatDate(item?.createTime)}</span>,
      },
    ],
    [+CompletedOrderOrderProcessEnum.SUB]: [
      {
        label: t('币种'),
        value: <span className={successColor}>{item?.coinName}</span>,
      },
      {
        label: t('金额'),
        value: <span>{RenderUtil.FormatAmount(item?.amount, 8)}</span>,
      },
      {
        label: t('手续费'),
        value: <span>{RenderUtil.FormatAmount(item?.fee, 8)}</span>,
      },
      {
        label: t('实际到账金额'),
        value: <span>{RenderUtil.FormatAmount(item?.actualAmount, 8)}</span>,
      },
      {
        label: t('进度'),
        value: (
          <>
            {item?.status === +CompletedOrderOrderStatusEnum.UnderReview && (
              <span className="text-left text-warningColor">{t('审核中')}</span>
            )}
            {item?.status === +CompletedOrderOrderStatusEnum.COMPLETED && (
              <span className={`text-left  ${successColor}`}>
                {t('已通过')}
              </span>
            )}
            {item?.status === +CompletedOrderOrderStatusEnum.DID_NOT_PASS && (
              <span className="text-left text-warningColor">{t('未通过')}</span>
            )}
          </>
        ),
      },
      {
        label: t('创建时间'),
        value: <span>{RenderUtil.formatDate(item?.createTime)}</span>,
      },
    ],
  };
  return (
    <div
      key={item}
    >
      <div className={`mb-1 ${titleColor} text-sm`}>
        {+item?.cardType === +CompletedOrderOrderProcessEnum.ADD ? t('充值') : t('提现')}
      </div>
      {/*展示的内容*/}
      <div className={`${bgColor}  rounded-md p-2`}>
        {showCardMap[item?.cardType]?.map((item, index) => {
          return (
            <div key={index}>
              <div className="flex justify-between items-center gap-2 text-xs">
              <span className={`mb-2 ${labelColor} flex-shrink-0`}>
                {item?.label}
              </span>
                <div className={`text-right ${valueColor}`}>{item?.value}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface IProps {
  item?: any;
  clickOnTheCard?: (data: any) => void;
  children?: ReactNode;
  isRight?: boolean;
  isReply?: boolean;
  isReplyBox?: boolean;
}
