import BaseTopNav from '@/components/base/baseTopNav';
import {cn, findJsonValue} from '@/utils';
import {useOnlineSupportModel} from '@/pages/onlineSupport/useOnlineSupportModel';
import {useMemo} from 'react';
import {HistorySession} from '@/assets/icons/comm/HistorySession';
import eventBus from '@/utils/evevtBus';
import {BusEnums} from '@/enums/busEnum';
import {history, useModel} from '@umijs/max';

// 官方客服显示头部组件
export default ({isOnlineStatus, readMsg}: any) => {
    const modelProps = useOnlineSupportModel() || {};
    const channelInfo = useMemo(() => {
        return modelProps.channelInfo;
    }, [modelProps.channelInfo]);
    const shopInfo = useMemo(() => {
        return {
            ...modelProps.channelInfo,
            businessAvatar: findJsonValue('businessAvatar', channelInfo), // 商家头像
            merchant: findJsonValue('merchant', channelInfo), // 商家名称
            volume: findJsonValue('volume', channelInfo), // 成交量
            orderRate: findJsonValue('orderRate', channelInfo), // 成单率
            avaCoinReleaseTime: findJsonValue('avaCoinReleaseTime', channelInfo), // 平均放币时间
            price: channelInfo?.rate, // 标价
            quantity: findJsonValue('quantity', channelInfo), // 数量
        };
    }, [modelProps.channelInfo]);
    const {isMobile} = useModel("system")

    return <BaseTopNav
        onBack={() => {
            readMsg?.()
            history.back()
        }}
        title={
            <div className={cn(`flex gap-2 justify-center items-center`,{
                "py-2": !isMobile
            })}>
                <span>{shopInfo?.merchant}</span>
                <div
                    className={cn(`w-[6px] h-[6px] rounded-full`, {
                        'bg-errorColor': !isOnlineStatus,
                        'bg-successColor': isOnlineStatus,
                    })}
                ></div>
            </div>
        }
        leftNode={isMobile ?   undefined :<></>}
        rightNode={
            isMobile ? <HistorySession className="pr-4 box-content text-backContrastColor/70" onClick={() => {
                eventBus.emit(BusEnums.IM_HISTORY_SESSION_OPEN, {id: shopInfo?.id});
            }}/> : null
        }
    />;
}