import { FundsOrderHistoryEnum, WalletTypeEnum } from '@/enums/businessEnum';
import useConstants from '@/pages/funds/orderHistory/index/useConstants';
import { cn } from '@/utils';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@@/exports';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function FeaturesFundingRecordsCard({
  item,
  clickOnTheCard,
  isRight = false,
  isReply = false,
  isReplyBox = false,
}: IProps) {
  const { t } = useTranslation();
  const { sourceOptions } = useConstants();
  const { upDownColorConfig } = useModel('system');
  // 收入
  const incomeColor =
    upDownColorConfig === 'upGreen' ? 'text-successColor' : 'text-errorColor';
  // 支出
  const expenditureColor =
    upDownColorConfig === 'upGreen' ? 'text-errorColor' : 'text-successColor';
  // 标题颜色
  const titleColor = useMemo(() => {
    if (isRight) {
      return 'text-imMyTextColor';
    } else if (isReplyBox) {
      return 'text-foreground';
    } else {
      return 'text-backContrastColor';
    }
  }, [isRight, isReply]);
  // label 颜色
  const labelColor = useMemo(() => {
    if (isRight) {
      return 'text-[#000]';
    } else if (isReplyBox) {
      return 'text-auxiliaryTextColor';
    } else {
      return 'text-backContrastColor';
    }
  }, [isRight, isReply]);
  // value颜色
  const valueColor = useMemo(() => {
    if (isRight) {
      return 'text-[#000]';
    } else if (isReplyBox) {
      return 'text-backContrastColor';
    } else {
      return 'text-backContrastColor';
    }
  }, [isRight, isReply]);
  // 背景颜色
  const bgColor = useMemo(() => {
    if (isRight) {
      return 'bg-[#fff]';
    } else if (isReplyBox) {
      return 'bg-background';
    } else {
      return 'bg-backgroundAuxiliaryColor';
    }
  }, [isRight, isReplyBox]);


  return (
    <div
      key={item}
      onClick={() => {
        clickOnTheCard && clickOnTheCard(item);
      }}
    >
      <div className="flex  justify-between mb-1 ">
        {
          <span className={cn(`${titleColor} text-sm`)}>
            {item?.feeMark === 0 || item?.feeMark === '0'
              ? sourceOptions?.find(
                  (i: any) => Number(i.value) === Number(item?.sourceType),
                )?.text
              : t('手续费')}
          </span>
        }
      </div>
      <div className={cn(`rounded-md w-full p-2 ${bgColor}`)}>
        {[ WalletTypeEnum.COIN ,WalletTypeEnum.CONTRACT,WalletTypeEnum.FASTCONTRACT].includes(item?.walletType)&&
        [FundsOrderHistoryEnum.CONTRACT_TRANSFER,FundsOrderHistoryEnum.CONTRACT_TRANSFER,FundsOrderHistoryEnum.CONTRACT_TRANSFER ].includes(item?.sourceType)&&
        (
            <div className="w-full flex justify-between mt-2 text-xs">
            <span className={`${labelColor} font-bold`}>
              {/*普通订单表单*/}
              {/* {item?.sourceType !== FundsOrderHistoryEnum.CONTRACT_TRANSFER && (
                <span>{item?.orderInfo?.name ?? item?.coinName}</span>
              )} */}
              {/*  划转的标题*/}
              {item?.walletType === WalletTypeEnum.COIN &&
                item?.sourceType === FundsOrderHistoryEnum.CONTRACT_TRANSFER && (
                  <span>{t('现货账户')}</span>
                )}
              {item?.walletType === WalletTypeEnum.CONTRACT &&
                item?.sourceType === FundsOrderHistoryEnum.CONTRACT_TRANSFER && (
                  <span>{t('合约账户')}</span>
                )}
              {item?.walletType === WalletTypeEnum.FASTCONTRACT &&
                item?.sourceType === FundsOrderHistoryEnum.CONTRACT_TRANSFER && (
                  <span>{t('快速合约账户')}</span>
                )}
            </span>
          </div>
        )
        }
        <div className="flex justify-between mt-2 text-xs text-right">
          <span className={`${labelColor} flex-shrink-0`}>{t('币种')}</span>
          {/*普通订单表单*/}
          {item?.sourceType !== FundsOrderHistoryEnum.CONTRACT_TRANSFER && (
            <span className={valueColor}>{item?.orderInfo?.name ?? item?.coinAlias}</span>
          )}
        </div>
        <div className="flex justify-between mt-2 text-xs text-right">
          <span className={`${labelColor} flex-shrink-0`}>{t('金额')}</span>
          {/*币币订单*/}
          {item?.operateDirection === 0 && (
            <span className={`${expenditureColor} flex-shrink-0`}>
              -{RenderUtil.FormatAmount(item?.operateBalance, 8, false)}
            </span>
          )}
          {item?.operateDirection === 1 && (
            <span className={`${incomeColor} flex-shrink-0`}>
              +{RenderUtil.FormatAmount(item?.operateBalance, 8, false)}
            </span>
          )}
        </div>
        <div className="flex justify-between mt-2 text-xs text-right">
          <span className={`${labelColor} flex-shrink-0`}>{t('资金方向')}</span>
          {item?.operateDirection === 0 && (
            <span className={`${expenditureColor} flex-shrink-0`}>
              {t('支出')}
            </span>
          )}
          {item?.operateDirection === 1 && (
            <span className={`${incomeColor} flex-shrink-0`}>{t('收入')}</span>
          )}
        </div>
        <div className="w-full flex justify-between mt-2 text-xs text-right">
          <span className={`${labelColor} flex-shrink-0`}>{t('创建时间')}</span>
          <span className={`ml-2 ${valueColor}`}>
            {RenderUtil.formatDate(item?.createTime,"YYYY-MM-DD HH:mm:ss")}
          </span>
        </div>
      </div>
    </div>
  );
}

interface IProps {
  item: any;
  clickOnTheCard?: (data: any) => void;
  children?: ReactNode;
  isRight?: boolean;
  isReply?: boolean;
  isReplyBox?: boolean;
}
