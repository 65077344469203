import BasePullDownToRefresh from '@/components/base/basePullDownToRefresh';
import { toast } from '@/layouts/components/ToastMessage';
import MsgItem from '@/pages/onlineSupport/components/Main/MsgItem';
import { toJsonData } from '@/utils/socket';
import { useModel } from '@@/exports';
import { useDebounceFn, useInViewport, useUpdateEffect } from 'ahooks';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PhotoProvider } from 'react-photo-view';
import { useOnlineSupportModel } from '../../useOnlineSupportModel';
import { chatDB } from '@/models/imSocket';
import { SEND_MESSAGE_STATUS } from '@/enums/businessEnum';

function MsgList() {
  const { renderMessageList: messageList, haveNoSuccessSendMsg } = useOnlineSupportModel() || {};
  const modelProps = useOnlineSupportModel() || {};
  const { customerServiceMessageUNREAD, sendImMessage: sendImMessageSocket } =
  useModel('imSocket') || {};
  const { t } = useTranslation();
  //记录所有item是否打开
  const list = useMemo(() => {
    //填充
    if (messageList?.length) {
      // 增加一个变量来存储前一条消息的时间
      let resMessage = _.cloneDeep(messageList);
      resMessage = resMessage?.filter?.(item => item?.groupId === modelProps.groupId)?.sort?.((a: any, b: any) => {
        if (haveNoSuccessSendMsg) {
          return b.createTime - a.createTime;
        } else {
          return b.offset - a.offset;
        }
      })?.map((item, index) => {
        const currentTime: any = new Date(item?.createTime);
        const currentDate = currentTime.toDateString(); // 格式化为 "Weekday Month Day Year"
        // 获取下一条消息的日期
        const nextItem = messageList[index + 1];
        const nextDate = nextItem
          ? new Date(nextItem?.createTime).toDateString()
          : null;

        // 如果下一条消息是不同日期或没有下一条消息，则显示日期
        const isDisplayTime = nextDate !== currentDate;
        return {
          ...item,
          payload: toJsonData(item.payload),
          isDisplayTime, // 每天第一条消息显示日期
        };
      });

      // 并且根据uuid去重
      // 使用 Map 进行去重，确保 uuid 唯一
      const uniqueMap = new Map();
      const uniqueItems: any[] = [];
      resMessage?.forEach((item) => {
        const data = toJsonData(item?.payload?.data);
        if (data?.uuid) {
          if (!uniqueMap.has(data?.uuid)) {
            uniqueMap.set(data?.uuid, 1);
            uniqueItems.push(item);
          }
        } else {
          uniqueItems.push(item);
        }
      });
      resMessage = uniqueItems;
      return resMessage;
    }
    return [];
  }, [messageList, haveNoSuccessSendMsg]);


  // 查看最后未读数条是否再视口中
  const isInViewport = useMemo(() => {
    if (customerServiceMessageUNREAD <= 0) return false;
    // 最后n条
    const lastN = list?.slice(0, customerServiceMessageUNREAD);
    // 查看这几条是否在视口中
    return lastN?.some?.((item: any) => {
      return modelProps.currentMessageListNewestId?.includes?.(item?.id);
    });
  }, [
    list,
    customerServiceMessageUNREAD,
    modelProps.currentMessageListNewestId,
  ]);
  useUpdateEffect(() => {
    if (isInViewport) {
      // 找到最新的消息id
      const findMessage = list?.find((item) => !!item?.id);
      if (findMessage?.id) {
        sendImMessageSocket({
          type: 'READ_MESSAGE',
          data: findMessage?.groupId,
          mark: findMessage?.id,
        } as any);
      }
    }
  }, [isInViewport]);

  const scrollToBottom = () => {
    // 拿到第一条消息
    modelProps.bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    // 找到最新的消息id
    modelProps.readMsg();
  };

  const [inViewport] = useInViewport(modelProps.bottomRef);
  const { readMessageId } = useModel('imSocket');
  // 当前向上的未读消息个数
  const [unreadCount, setUnreadCount] = useState(0);
  useUpdateEffect(() => {
    const reversedArray = list.slice().reverse();
    let endIndex = -1;
    for (let i = 0; i < reversedArray.length; i++) {
      if (readMessageId?.includes(reversedArray[i]?.id)) {
        endIndex = i;
      }
    }
    if (modelProps?.unreadCount > 0 && endIndex !== -1) {
      setUnreadCount(modelProps?.unreadCount - endIndex + 1);
    }
  }, [modelProps?.unreadCount, list]);
  const { isPc } = useModel('system');

  // 第一条消息
  const firstItem = useMemo(() => {
    return list?.find?.((item: any) => item?.offset === 1);
  }, [list]);
  // 长按菜单
  const [isMenuOpen, setIsMenuOpen] = useState();
  const loadingRef = useRef<HTMLDivElement>(null);

  const [loadingRefViewport] = useInViewport(loadingRef);

  // pc下拉刷新
  const listRef = useRef<any>();
  const { getUserInfoCustomerServiceReq } = useModel('cacheAvatarList');
  // 实时赋值ref，避免闭包陷阱
  useEffect(() => {
    const userIds: string[] = [];
    if (list?.length > 0) {
      chatDB.setItems(list.slice(0, 10)?.map?.((item: any) => ({
        ...item,
        payload: JSON.stringify(item.payload),
      })));
    }
    list.forEach((item) => {
      if (!userIds.includes(item.userInfo?.id)) {
        userIds.push(item.userInfo?.id);
      }
    });
    if (userIds.length > 0) {
      getUserInfoCustomerServiceReq(userIds, modelProps?.groupId!);
    }
    listRef.current = list;
  }, [list]);
  const { run: pcRefreshFn } = useDebounceFn(
    (_list, _groupId) => {
      modelProps.getMessageListReq({
        offset: modelProps?.getMaxAndMinOffsetRef.current?.min,
        groupId: _groupId,
      });
    },
    {
      wait: 1000,
    },
  );
  useEffect(() => {
    if (!isPc) return;

    if (loadingRefViewport && !firstItem) {
      pcRefreshFn(listRef.current, modelProps.groupId);
    }
  }, [loadingRefViewport, modelProps.groupId]);


  return (
    <>
      {/*顶置未读消息*/}
      {unreadCount > 0 && modelProps.setUnreadCount > 0 && (
        <div
          className="fixed top-[44px] animate-bounce right-3 mt-1 bg-[#f3f4f6] z-[999] dark:!bg-backgroundAuxiliaryColor rounded-[20px] py-1 px-2 text-xs"
          onClick={() => {
            // 未读dom
            const unreadDom = document.getElementById(
              `message-${modelProps?.unreadCount - 1}`,
            );
            if (unreadDom) {
              unreadDom?.scrollIntoView({ behavior: 'smooth' });
            }
            modelProps.setUnreadCount(0);
          }}
        >
          <div className="flex items-end ">
            <img
              src={require('@/assets/img/im/top.png')}
              className="w-[18px]"
              alt=""
            />
            <span className="ml-1">
              {t('{{num}} 条未读消息', {
                num: unreadCount,
              })}
            </span>
          </div>
        </div>
      )}
      {/*消息内容*/}
      <BasePullDownToRefresh
        classNames={{
          base: 'h-full',
          content: 'h-full',
        }}
        className="h-full"
        loading={modelProps.loading}
        refreshFn={() => {
          if (!modelProps.chartList?.[0]?.id || isPc) return;
          if (firstItem) return toast.info(t('没有更多消息了'));
          // 调用接口
          modelProps.getMessageListReq({
            offset: modelProps?.getMaxAndMinOffsetRef.current?.min,
            groupId: modelProps.chartList?.[0]?.id,
          });

        }}
      >
        <PhotoProvider>
          {/* IM 聊天内容区域 */}
          <div
            id={'message-list'}
            className="h-full pb-[60px] w-full scroll-none flex flex-col-reverse overflow-y-auto px-4  relative"
            onScroll={() => {
              setIsMenuOpen(-1);
            }}
          >
            {/*不准删除,占位置(也是可以用来滚动到最底部),谁删谁噶*/}
            <div
              className="flex-1 flex-shrink-1"
              ref={modelProps.bottomRef}
            ></div>
            {list?.map((item: any, index: number) => {
              return (
                <MsgItem
                  key={index}
                  item={item}
                  index={index}
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
              );
            })}
            {
              !isPc && <div className="h-[70px] flex-shrink-0">
              </div>
            }
            {isPc && !firstItem && list?.length !== 0 && (
              <div
                ref={loadingRef}
                className="text-auxiliaryTextColor text-sm text-center"
              >
                {t('正在加载中...')}
              </div>
            )}
          </div>
        </PhotoProvider>
      </BasePullDownToRefresh>

      {+customerServiceMessageUNREAD > 0 && !inViewport && (
        <div
          className="fixed bottom-[90px] animate-bounce left-3 mt-1 bg-[#f3f4f6] z-[999] dark:!bg-backgroundAuxiliaryColor rounded-[20px] py-1 px-2 text-xs"
          onClick={() => {
            // 滚动到最底部并且已读最新消息
            scrollToBottom();
          }}
        >
          <div className="flex items-end">
            <img
              src={require('@/assets/img/im/top.png')}
              className="w-[18px] rotate-180"
              alt=""
            />
            <span className="ml-1">
              {t('{{num}} 条新消息', {
                num: customerServiceMessageUNREAD,
              })}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default MsgList;
