import BaseModal from '@/components/base/baseModal';
import { ImMessageTypeEnum } from '@/enums/businessEnum';
import { useOnlineSupportModel } from '../../useOnlineSupportModel';
import OnlineSupportExpressionDrawer from './OnlineSupportExpressionDrawer';
import OnlineSupportQuickOrderDrawer from './OnlineSupportQuickOrderDrawer';
import QuickPhrases from './QuickPhrases';
import useConstant from './useConstant';
import HistorySessionModal from '@/pages/onlineSupport/components/Dialog/HistorySessionModal';

// QuickPhrases 快捷短语弹窗
// OnlineSupportQuickOrderDrawer 快捷订单弹窗

export default () => {
  const modelProps = useOnlineSupportModel() || {};

  return (
    <div
      onClick={(event) => {
        event.preventDefault();
      }}
    >


      {/* //快捷短语弹窗 */}
      <QuickPhrases
        isOpen={modelProps.isOpen}
        onClose={modelProps.onClose}
        setMsg={(item: string) => {
          modelProps.sendMsg({
            type: ImMessageTypeEnum.TEXT,
            data: {
              text: item,
            },
          });
        }}
      />
      {/*快捷订单弹窗*/}
      <OnlineSupportQuickOrderDrawer
        groupId={modelProps.chartList?.[0]?.id}
        sendMsg={modelProps.sendMsg}
        isOpen={modelProps.isOpen2}
        onClose={modelProps.onClose2}
      />


      {/*历史会话*/}
      <HistorySessionModal />
    </div>
  );
};
