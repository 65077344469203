import {
  ImMessageStatusEnum,
  SEND_MESSAGE_STATUS,
  ImMessageTypeEnum, CustomerServiceTypeEnum,
} from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import {
  getChartList,
  getChartMessageList, getRechargeChannelByIdApi,
  uploadFilesS3Api,
  withdrawMessageApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { findJsonValue, generateRandomString } from '@/utils';
import { useDisclosure } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useClickAway, useMount, useReactive, useUpdateEffect, useDebounceFn } from 'ahooks';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import eventBus from '@/utils/evevtBus';
import { BusEnums } from '@/enums/busEnum';
import { toJsonData } from '@/utils/socket';
import _ from 'lodash';

export const OnlineSupportContext = createContext<any>({});

export const useOnlineSupportModel = () => {
  const context = useContext(OnlineSupportContext);
  if (!context) {
    throw new Error(
      'useContractTradeModel must be used within a ContractTradeProvider',
    );
  }
  return context;
};

export default (isComponent = false) => {
  const { isPc } = useModel('system');
  /*-------------------初始化消息数据-------------------------------*/
  //获取imSocket
  const {
    imMessage,
    sendImMessage: sendImMessageSocket,
    currentSocketMessage,
    setImMessage,
    imSocketState,
  } = useModel('imSocket');
  const { user } = useModel('user');
  // 滚动到最底部
  const bottomRef = useRef<HTMLDivElement | any>();
  //历史消息记录
  const [messageList, setMessageList] = useState<any[]>([]);
  //下拉loading展示效果
  const [isShowLoading, setIsShowLoading] = useState(false);

  // 通道id,发送信息等缓存信息
  const [imParmaData, setImParmaData] = useLocalStorage({
    key: CacheEnum.APP_IM_DATA,
    defaultValue: [],
  });
  const [type] = useLocalStorage({
    key: CacheEnum.APP_LOGIN_TYPE,
  });
  const {
    groupType = +type === 1 ? CustomerServiceTypeEnum.TEMPORARY_CUSTOMER_SERVICE : CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE,
    msg: orderSendMsg,
    channelId: defaultChannelId,
    rechargeType,
  } = useMemo(() => {
    return imParmaData || {};
  }, [imParmaData]);
  const [channelId, setChannelId] = useState(defaultChannelId);
  const channelIdRef = useRef(channelId);
  useEffect(() => {
    channelIdRef.current = channelId;
  }, [channelId]);
  //拿到当前设备是否是移动端
  const { isMobile } = useModel('system');
  //国际化
  const { t } = useTranslation();
  //文本信息
  const [msg, setMsg] = useState('');
  //当前要编辑的消息
  const [currentEditMessage, setCurrentEditMessage] = useState<any>({});
  //快捷语 抽屉
  const { isOpen, onClose, onOpen } = useDisclosure();
  const OldValue = useReactive({ value: '' });
  // mitt 监听
  const eventHandlerRef = useRef<(val: any) => void>();
  // 未读数字
  const [unreadCount, setUnreadCount] = useState(0);
  // 是否是第一次请求分页
  const [isFirstRequest, setIsFirstRequest] = useState(true);
  const [channelInfo, setChannelInfo] = useState<any>({});
  // 通道信息
  const { run: getRechargeChannelByIdReq } = useReq(getRechargeChannelByIdApi, {
    manual: true,
    onSuccess(res) {
      if (res) {
        setChannelInfo(res);
      }
    },
  });
  useMount(() => {
    if (isPc && !isComponent) return;
    if (!channelIdRef.current) return;
    getRechargeChannelByIdReq({ channelId: channelIdRef.current });
  });
  // c2c商家信息
  const shopInfo = useMemo(() => {
    if (!channelInfo) return {};
    return {
      ...channelInfo,
      businessAvatar: findJsonValue('businessAvatar', channelInfo), // 商家头像
      merchant: findJsonValue('merchant', channelInfo), // 商家名称
      volume: findJsonValue('volume', channelInfo), // 成交量
      orderRate: findJsonValue('orderRate', channelInfo), // 成单率
      avaCoinReleaseTime: findJsonValue('avaCoinReleaseTime', channelInfo), // 平均放币时间
      price: channelInfo?.rate, // 标价
      quantity: findJsonValue('quantity', channelInfo), // 数量
    };
  }, [channelInfo]);


  // 向上面加载更多的，请求接口
  const {
    data: currentMessageListNewest,
    run: getMessageListReq,
    loading,
  } = useReq(getChartMessageList, {
    manual: true,
    debounceWait: 0,
    formatResult: (res: any) => {
      if (res.data && res?.data?.messageRespList?.length !== 0 && Array.isArray(res?.data?.messageRespList)) {
        // 查找未发送成功的数据，并且如果返回列表中有相同的uuid那么就替换对应的消息及状态
        const haveNoSuccessSendMsg = imMessage?.some?.
        ((item: any) => item?.sendStatus !== SEND_MESSAGE_STATUS.SUCCEED && item?.showDirection === 0);
        if (haveNoSuccessSendMsg) {
          setImMessage((prev) => {
            return prev?.map?.(item => {
              // 对方发的消息
              if (item?.showDirection !== 0) return item;
              // 拿uuid去寻找接口返回的消息
              const payload = toJsonData(item?.payload);
              const payloadData = toJsonData(payload?.data);
              const uuid = payloadData?.uuid;
              const findMessage = res?.data?.messageRespList?.find?.((_item: any) => {
                  const _payload = toJsonData(_item?.payload);
                  const _payloadData = toJsonData(_payload?.data);
                  const _uuid = _payloadData?.uuid;
                  return _uuid === uuid;
                },
              );
              // 如果有则返回，更改状态
              if (findMessage) {
                return {
                  ...findMessage,
                  sendStatus: SEND_MESSAGE_STATUS.SUCCEED,
                };
              }
              // 没有就算了
              return item;
            });
          });
        }
        // 最新消息的id作为已读
        if (isFirstRequest) {
          // 找到最新的消息id
          const offset = Math.max(...res?.data?.messageRespList?.map((item: any) => item.offset));
          const findMessage = res?.data?.messageRespList?.find(
            (item: any) => item?.offset === offset,
          );
          if (findMessage?.id) {
            sendImMessageSocket({
              type: 'READ_MESSAGE',
              data: findMessage?.groupId,
              mark: findMessage?.id,
            } as any);
          }
          // 确认消息
          handMessageConfirmation();
        }
        setIsFirstRequest(false);
        setUnreadCount(res?.data?.unreadCount);
        // 筛选出不在im消息队列中，方便后续缓存
        let _messageList = [
          ...(messageList ?? []),
          ...(res?.data?.messageRespList ?? []),
        ]?.filter(item => {
          return !imMessage?.some?.(i => i?.id === item?.id);
        });
        setMessageList(_messageList);
        return res?.data?.messageRespList;
      }
      return [];
    },
    onFinally() {
      setIsShowLoading(false);
    },
  });
  //获取亚马逊S3上传
  const { runAsync: uploadFilesS3Req, data: uploadFilesS3 } = useReq(
    uploadFilesS3Api,
    {
      manual: true,
    },
  );
  // 缓存值只作为初始值
  const [chartList, setChartList] = useLocalStorage({
    key: user?.id + CacheEnum.APP_LOCAL_GROUP_ID + channelIdRef.current,
    defaultValue: [],
  });
  // 群id
  const [groupIds, setGroupId] = useState(chartList);
  // 避免闭包
  const groupIdsRef = useRef(chartList);
  useEffect(() => {
    groupIdsRef.current = groupIds;
  }, [groupIds]);
  const { loading: chartLoading = true, runAsync: getChartListReq } = useReq(
    getChartList,
    {
      formatResult: (res: any) => {
        setGroupId(res?.data ?? []);
        setChartList(res?.data ?? []);
        return res?.data ?? [];
      },
      manual: true,
    },
  );
  //获取群id
  useMount(async () => {
    if (navigator.onLine) {
      if (isPc && !isComponent) return;
      await getChartListReq({ type: groupType, channelId: channelIdRef.current }).then((res: any) => {
        if (res?.length > 0) {
          getMessageListReq({
            groupId: res[0].id,
            timestamp: undefined,
          });
          // 发送外部传入的消息
          if (!!orderSendMsg) {
            setTimeout(() => {
              sendMsg({
                type: ImMessageTypeEnum.TEXT,
                data: {
                  text: orderSendMsg,
                },
              }, res[0].id);
              setImParmaData((prev: any) => {
                return {
                  ...prev,
                  msg: '',
                };
              });
            }, 50);
          }
        }
      });
    }
  });

  //当前需要回复的内容
  const [whatNeedsToBeRepliedTo, setWhatNeedsToBeRepliedTo] =
    useState<any>(null);
  //当前回复的内容的信息
  const [currentReplyContent, setCurrentReplyContent] = useState<any>(null);
  /*-------------------发送消息成功关闭工具栏；点击周围关闭工具栏-------------------------------*/
  const InputBoxRef = useRef<HTMLDivElement>(null);
  //工具栏是否显示
  const [showToolbar, setShowToolbar] = useState<boolean>(false);
  //订单发送抽屉
  const {
    isOpen: isOpen2,
    onClose: onClose2,
    onOpen: onOpen2,
  } = useDisclosure();
  //表情抽屉
  const {
    isOpen: isOpen3,
    onClose: onClose3,
    onOpen: onOpen3,
  } = useDisclosure();
  //撤回消息接口
  const { run: withdrawMessageInterfaceReq } = useReq(withdrawMessageApi, {
    manual: true,
    onSuccess() {
      toast.success(t('已撤回'));
    },
  });
  useClickAway(() => {
    setShowToolbar(false);
  }, InputBoxRef);

  /*-------------------最终渲染列表-------------------------------*/
  //如果当前socket发送数据改变，则代表有编辑过或者撤回的操作;则需要在已有消息列表中查询并且修改
  useUpdateEffect(() => {
    //修改消息
    if (+currentSocketMessage?.status === ImMessageStatusEnum.EDIT_MESSAGE) {
      setMessageList((old) => {
        return old?.map((item) => {
          if (item.id !== currentSocketMessage.id) return item;
          return {
            ...item,
            status: ImMessageStatusEnum.EDIT_MESSAGE,
            payload: currentSocketMessage?.payload,
          };
        });
      });
    }
    //撤回消息
    if (
      +currentSocketMessage?.status === ImMessageStatusEnum.WITHDRAW_MESSAGE
    ) {
      setMessageList((old) => {
        return old.map((item) => {
          if (item.id !== currentSocketMessage.id) return item;
          return {
            ...item,
            status: ImMessageStatusEnum.WITHDRAW_MESSAGE,
          };
        });
      });
    }
  }, [currentSocketMessage]);
  //组装数据
  const [renderMessageList, setRenderMessageList] = useState<any[]>([]);
  const { haveNoSuccessSendMsg, getMaxAndMinOffset, getMaxAndMinCreateTime } = useMemo(() => {
    const haveNoSuccessSendMsg = renderMessageList?.some?.
    ((item: any) => [SEND_MESSAGE_STATUS.WAIT, SEND_MESSAGE_STATUS.FAIL].includes(item?.sendStatus) && item?.showDirection === 0);
    const offsetSortMsgList = _.cloneDeep(renderMessageList)?.filter(item => !!item?.id).sort?.((a, b) => a.offset - b.offset);
    const creteTimeSortMsgList = _.cloneDeep(renderMessageList)?.filter(item => !!item?.id).sort?.((a, b) => a.createTime - b.createTime);

    return {
      haveNoSuccessSendMsg,
      getMaxAndMinOffset: {
        min: offsetSortMsgList[0]?.offset,
        max: offsetSortMsgList[offsetSortMsgList?.length - 1]?.offset,
      },
      getMaxAndMinCreateTime: {
        min: creteTimeSortMsgList[0]?.createTime,
        max: creteTimeSortMsgList[creteTimeSortMsgList?.length - 1]?.createTime,
      },
    };
  }, [renderMessageList]);
  const getMaxAndMinCreateTimeRef = useRef({});
  const getMaxAndMinOffsetRef = useRef<{
    min: number;
    max: number;
  }>({
    min: 0,
    max: 0,
  });
  useEffect(() => {
    getMaxAndMinCreateTimeRef.current = getMaxAndMinCreateTime;
  }, [getMaxAndMinCreateTime]);
  useEffect(() => {
    getMaxAndMinOffsetRef.current = getMaxAndMinOffset;
  }, [getMaxAndMinOffset]);
  useEffect(() => {
    // 专属群类型的消息(普通客服,C2C客服)
    const imMsgList = imMessage?.filter?.(item => {
      // 匿名客服
      if (+groupType === 7) return true;
      // 普通客服
      if ((!item?.channelId || item?.channelId === 'DEFAULT') && +groupType === 5) return true;
      // 有的话就匹配类型
      return item?.channelId === channelIdRef.current;
    });
    // 根据id去重，Map是key唯一值
    const noIdMsg = imMsgList.filter(item => item.id === undefined);
    const combinedList = [...messageList, ...imMsgList]?.filter(item => !!item?.id);
    const uniqueList = Array.from(new Map(combinedList.map(item => [item.id, item])).values());
    setRenderMessageList([...uniqueList, ...noIdMsg]?.filter?.(item => item?.groupId === groupIdsRef.current?.[0]?.id));
  }, [messageList, imMessage, groupType, channelIdRef.current]);
  const renderMessageListRef = useRef(renderMessageList);
  useEffect(() => {
    renderMessageListRef.current = renderMessageList;
  }, [renderMessageList]);

  const readMsg = () => {
    // 找到最新的消息id
    const maxOffset = Math.max(
      ...renderMessageListRef.current?.map((item: any) => item.offset),
    );
    const findMessage = renderMessageListRef.current?.find(
      (item: any) => item?.offset === maxOffset,
    );
    if (findMessage?.id) {
      sendImMessageSocket({
        type: 'READ_MESSAGE',
        data: findMessage?.groupId,
        mark: findMessage?.id,
      } as any);
    }
  };

  // 切换会话列表
  const switchChat = async ({ id, _groupType, _groupId }: any) => {
    if (!id && _groupType !== CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE) return;
    // 标记已读（上一次会话）
    readMsg();
    setMessageList([]);
    setIsFirstRequest(true);
    if (_groupType !== CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE) {
      getRechargeChannelByIdReq({ channelId: id });
    }
    setChannelId(id);
    channelIdRef.current = id;
    if (navigator.onLine) {
      getChartListReq({ type: _groupType ?? groupType, channelId: id }).then((res: any) => {
        if (res?.length > 0) {
          getMessageListReq({
            groupId: res[0].id,
            timestamp: undefined,
          });
        }
      });
    }
    setImParmaData({
      channelId: id,
      groupType: _groupType ?? groupType,
    });
  };


  /*-------------------缓存数据到model-------------------------------*/
  const { findNotCachedSavaData } = useModel('orderHistoryCache');

  //监听渲染数组长度增加就筛选出Order订单存入缓存
  useUpdateEffect(() => {
    findNotCachedSavaData(renderMessageList);
  }, [renderMessageList]);

  /*-------------------发送消息-------------------------------*/
  const sendMsg = (content?: any, groupId?: string) => {
    let sendMsgContent: any;
    //回复功能发送
    if (currentReplyContent !== null) {
      sendMsgContent = {
        type: currentReplyContent?.payload?.type,
        data: JSON.stringify({
          ...currentReplyContent?.payload?.data,
          createTime: currentReplyContent?.createTime,
          userInfo: currentReplyContent?.userInfo,
          content: { ...content },
          imgType: content?.imgType,
          replyId: currentReplyContent?.id,
          channelId: channelIdRef.current,
          groupId: groupId ?? groupIdsRef.current?.[0]?.id,
          uuid: content?.type === ImMessageTypeEnum.IMAGE ? content?.uuid : generateRandomString(10),
        }),
        groupId: groupId ?? groupIdsRef.current?.[0]?.id,
        groupType: groupType,

      };
    } else {
      sendMsgContent = {
        type: content?.type,
        id: content?.id,
        channelId: channelIdRef.current,
        groupType: groupType,
        data: JSON.stringify({
          ...content.data,
          imgType: content?.imgType,
          groupId: groupId ?? groupIdsRef.current?.[0]?.id,
          uuid: content?.type === ImMessageTypeEnum.IMAGE ? content?.uuid : generateRandomString(10),
        }),
        groupId: groupId ?? groupIdsRef.current?.[0]?.id,
      };
    }

    //先默认推入消息当中，等到socket返回数据了在将数据替换 这样做可以更快发送数据用户看起来
    if (!content?.id && content?.imgType !== 'url') {
      OldValue.value = '';
      setImMessage((old: any) => {
        return [
          ...old,
          {
            showDirection: 0,
            channelId: channelIdRef.current,
            groupType: groupType,
            createTime: new Date().getTime(),
            status: 1,
            payload: JSON.stringify(sendMsgContent),
            sendStatus: imSocketState
              ? SEND_MESSAGE_STATUS.WAIT
              : SEND_MESSAGE_STATUS.FAIL,
            groupId: groupId ?? groupIdsRef.current?.[0]?.id,
          },
        ];
      });
    }
    // 如果是base64本地预览不要发送
    if (content?.imgType === 'base64') return;
    setTimeout(() => {
      //  滚动到最底部
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      //发送
      sendImMessageSocket({
        type: 'SEND_MESSAGE',
        data: {
          ...sendMsgContent,
          groupType: groupType,
          channelId: channelIdRef.current,
          groupId: groupId ? groupId : groupIdsRef.current?.[0]?.id,
        },
      } as any);
      setCurrentReplyContent(null);
      setWhatNeedsToBeRepliedTo(null);
      setMsg('');
      setCurrentEditMessage({});
      onClose2();
      onClose3();
      setShowToolbar(false);
    }, 50);
  };
  // 从资金记录详情中跳转到客服聊天【保存零食详情数据】
  const [saveFundsDetails, setSaveFundsDetails] = useState();
  // 当前在视口中的消息id
  const [currentMessageListNewestId, setCurrentMessageListNewestId] = useState([]);

  const { run: runCurrent } = useDebounceFn((val: any) => {
    val?.data && !loading && sendMsg({
      type: ImMessageTypeEnum.ORDER,
      data: { type: val?.data?.cardType, id: val?.data?.id },
    });
  }, {
    wait: 1000,
  });
  // 监听详情消息
  useEffect(() => {
    eventHandlerRef.current = (val: any) => {
      runCurrent(val);
    };
  }, []);
  // 消息确认
  const handMessageConfirmation = () => {
    if (!chartList?.[0]?.workOrderId) return;
    // 消息最大偏移量
    const { max } = getMaxAndMinOffsetRef.current;
    sendImMessageSocket({
      type: 'MESSAGE_CONFIRMATION',
      data: {
        id: chartList?.[0]?.id,
        workOrderId: chartList?.[0]?.workOrderId,
        msgMaxOffset: max,
        type: groupType,
        channelId: channelIdRef.current,
      },
    });
  };
  // 消息重新发送
  const handMessageResend = () => {
    const sendNoSuccessSendMsg = imMessage
      ?.filter?.(
        (item: any) => {
          const payload = toJsonData(item?.payload);
          if (payload.data) {
            const data = toJsonData(payload.data);
            if (data?.imgType === 'base64' || data?.url?.length > 500) return false;
          }
          return +item?.showDirection === 0 && +item?.sendStatus !== SEND_MESSAGE_STATUS.SUCCEED && !item?.id;
        },
      )
      ?.map?.((item: any) => toJsonData(item?.payload));
    // 消息未发送成功的个数
    sendNoSuccessSendMsg?.forEach((sendMsg: any) => {
      sendImMessageSocket({
        type: 'SEND_MESSAGE',
        data: {
          ...sendMsg,
          groupType: sendMsg?.groupType ?? groupType,
          channelId: sendMsg?.channelId ?? channelIdRef.current,
          groupId: chartList?.[0]?.id,
        },
      });
    });
  };
  // 消息确认和重新发送
  useEffect(() => {
    // 将发送中的图片并且是base64变为失败
    setImMessage(prev => {
      return prev?.map(item => {
        const payload = toJsonData(item?.payload);
        if (payload.data) {
          const data = toJsonData(payload.data);
          if (data?.imgType === 'base64' || data?.url?.length > 500) return {
            ...item,
            sendStatus: SEND_MESSAGE_STATUS.FAIL,
          };
        }
        return item;
      });
    });
    // 消息确认
    eventBus.on(BusEnums.MESSAGE_LOST, handMessageConfirmation);

    // 消息检查是否有未发送的消息（重新发送）
    eventBus.on(BusEnums.MESSAGE_PUSH_FINISH, handMessageResend);
    // 切换会话
    if (isComponent) {
      eventBus.on(BusEnums.CHANGE_GROUP, switchChat);
    }
    return () => {
      readMsg();
      if (isComponent) {
        eventBus.off(BusEnums.CHANGE_GROUP, switchChat);
      }
      eventBus.off(BusEnums.MESSAGE_LOST, handMessageConfirmation);
      eventBus.off(BusEnums.MESSAGE_PUSH_FINISH, handMessageResend);
    };
  }, []);


  return {
    shopInfo,
    currentMessageListNewestId,
    setCurrentMessageListNewestId,
    OldValue,
    imSocketState,
    messageList,
    saveFundsDetails,
    setSaveFundsDetails,
    loading: loading,
    whatNeedsToBeRepliedTo,
    setWhatNeedsToBeRepliedTo,
    chartList,
    getMessageListReq,
    currentReplyContent,
    setCurrentReplyContent,
    currentMessageListNewest,
    isShowLoading,
    setIsShowLoading,
    msg,
    setMsg,
    t,
    onOpen,
    onOpen2,
    showToolbar,
    imMessage,
    isMobile,
    setShowToolbar,
    isOpen,
    onClose,
    isOpen2,
    onClose2,
    InputBoxRef,
    renderMessageList,
    sendMsg,
    currentEditMessage,
    setCurrentEditMessage,
    withdrawMessageInterfaceReq,
    groupId: groupIdsRef.current?.[0]?.id,
    isOpen3,
    onOpen3,
    onClose3,
    uploadFilesS3,
    uploadFilesS3Req,
    chartLoading,
    unreadCount,
    setUnreadCount,
    bottomRef,
    handMessageConfirmation,
    channelInfo,
    channelId: channelIdRef.current,
    switchChat,
    setImParmaData,
    rechargeType,
    imParmaData,
    haveNoSuccessSendMsg,
    getMaxAndMinCreateTime,
    getMaxAndMinCreateTimeRef,
    getMaxAndMinOffsetRef,
  };
};
