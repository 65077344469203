import OnlineSupportView from "@/pages/onlineSupport/OnlineSupportView";
import CustomerServiceListView from "@/pages/customerServiceList/CustomerServiceListView";

export default ()=>{
    return <div className="fixed pt-2 pb-2 left-0 right-0 gap-2 top-[60px] bottom-0 bg-backgroundAuxiliaryColor flex items-center justify-center">
        <div className="w-[375px]  bg-background h-full">
            <CustomerServiceListView/>
        </div>
        <div className="w-[800px] h-full  bg-background">
            <OnlineSupportView isComponent={true}/>
        </div>
    </div>
}