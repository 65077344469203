import BaseModal from '@/components/base/baseModal';
import {useTranslation} from 'react-i18next';

/**
 * QuickPhrases 快捷短语
 */
type iprops = {
  isOpen: boolean;
  setMsg: any;
  onClose: any;
};
export default function (props: iprops) {
  const { isOpen, onClose, setMsg } = props || {};
  const { t } = useTranslation();

  const arr = [
    t('我想了解一下我的账户资金情况。'),
    t('请问最新的[股票名称]价格是多少？'),
    t('今天有哪些重要的市场新闻？'),
    t('能否得到最近一周的[某行业]市场分析报告？'),
    t('如何进行股票买卖？'),
    t('请问我应该怎样设置止损？'),
    t('我的交易平台无法正常打开，需要怎么处理？'),
  ];

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}    classNames={{
      modal: '!rounded-none bg-background',
    }}>
      <div className="pt-1 text-foreground px-4 h-full max-w-[100%] pb-[70px] max-h-[400px] overflow-y-scroll">
        {arr?.map((item) => {
          return (
            <div
              key={item}
              className="break-words w-full"
              onClick={() => {
                setMsg(item);
                onClose();
              }}
            >
              <div className="bg-backgroundAuxiliaryColor rounded-md break-words mb-3 p-2">
                {item}
              </div>
            </div>
          );
        })}
      </div>
    </BaseModal>
  );
}
