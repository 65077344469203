/**
 * 在线客服
 * **/
import {useModel} from '@umijs/max';
import {useEffect} from 'react';
import Bottom from './components/Bottom';
import DialogGroup from './components/Dialog/DialogGroup';
import Header from './components/Header';
import MsgList from './components/Main/MsgList';
import useStateHooks, {OnlineSupportContext} from './useOnlineSupportModel';
import PCOnlineSupportView from "@/pages/onlineSupport/PCOnlineSupportView";
import {cn} from "@/utils";
import useNetworkTest from "@/pages/onlineSupport/useNetworkTest";

// IM 在线客服 1.0
export default ({isComponent}: {
    isComponent?: boolean
}) => {
    const {isPc} = useModel("system")

    /*--------------获取状态-----------------*/
    const modelProps = useStateHooks(isComponent) || {};
    const {language} = useModel('language');
    const {sendImMessage} = useModel('imSocket');

    useEffect(() => {
        sendImMessage({
            type: 'language',
            data: language,
        });
    }, []);
    const {isOnlineStatus, connectTimes, connectSocket} = useNetworkTest()

    if (!isComponent && isPc) return <PCOnlineSupportView/>

    return (
        <OnlineSupportContext.Provider value={modelProps}>
            <Header isOnlineStatus={isOnlineStatus} connectTimes={connectTimes} connectSocket={connectSocket}/>
            <div className={cn(`flex flex-col sm:relative h-[100vh] sm:h-[87vh] overflow-hidden w-full`, {
                "!h-[81vh]": !isOnlineStatus
            })}>
                {/* 顶部标题 &  重连逻辑 */}
                <div className={`flex-1  overflow-hidden`}>
                    <MsgList/>
                </div>
                {/*底部输入框*/}
                <Bottom/>

                {/* 弹框 */}
                <DialogGroup/>
            </div>
        </OnlineSupportContext.Provider>
    );
};
