import { CarbonCloseFilled } from '@/assets/icons/comm/CarbonCloseFilled';
import { BusEnums } from '@/enums/busEnum';
import eventBus from '@/utils/evevtBus';
import { useModel } from '@umijs/max';
import { useMount, useRafInterval, useUpdateEffect } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnlineSupportModel } from '../../useOnlineSupportModel';

import { MaterialSymbolsAutorenew } from '@/assets/icons/mine/MaterialSymbolsAutorenew';
import { RechargeChannelTypeEnum } from '@/enums/businessEnum';
import { toast } from '@/layouts/components/ToastMessage';
import {
  IM_HEART_BEAT_RECONNECT_INTERVAL,
  IM_MAX_RECONNECT_COUNT,
} from '@/models/imSocket';
import BankCardRechargeTopNav from '@/pages/onlineSupport/components/Header/BankCardRechargeTopNav';
import C2CTopNav from '@/pages/onlineSupport/components/Header/C2CTopNav';
import CommTopNav from '@/pages/onlineSupport/components/Header/CommTopNav';
import VirtualCurrencyRechargeTopNav from '@/pages/onlineSupport/components/Header/VirtualCurrencyRechargeTopNav';
import { cn } from '@/utils';

export default ({isOnlineStatus,connectSocket,connectTimes}:any) => {
  const { t } = useTranslation();
  const { isPc } = useModel('system');
  const { renderMessageList: messageList } = useOnlineSupportModel() || {};
  const { sendImMessage: sendImMessageSocket } =
  useModel('imSocket') || {};
  // 是否在线（延迟500ms显示，不然屏幕闪动）
  const modelProps = useOnlineSupportModel() || {};
  const channelInfo = useMemo(() => {
    return modelProps.channelInfo;
  }, [modelProps.channelInfo]);

  useUpdateEffect(() => {
    if (isOnlineStatus) {
      setTimeout(() => {
        modelProps.handMessageConfirmation();
      }, 2000);
    }
  }, [isOnlineStatus]);

  const readMsg = () => {
    // 找到最新的消息id
    const maxCreateTime = Math.max(
      ...messageList?.map((item: any) => item.createTime),
    );
    const findMessage = messageList?.find(
      (item: any) => item?.createTime === maxCreateTime,
    );
    if (findMessage?.id) {
      sendImMessageSocket({
        type: 'READ_MESSAGE',
        data: findMessage?.groupId,
        mark: findMessage?.id,
      } as any);
    }
  };

  return (
    <>
      <div
        className={cn('  top-0 w-full z-[20] bg-background   sm:z-[4]', {
          'fixed': !isPc,
          'border-b-1 border-b-borderColor': isPc,
        })}
      >
        {/*普通客服展示*/}
        {!modelProps.channelId && (
          <CommTopNav isOnlineStatus={isOnlineStatus} />
        )}
        {/*充值客服显示*/}
        {modelProps.channelId && (
          <>
            {/*C2C充值*/}
            {(+modelProps?.rechargeType === RechargeChannelTypeEnum.C2C_BUY ||
              channelInfo?.rechargeType ===
              RechargeChannelTypeEnum.C2C_BUY) && (
              <C2CTopNav isOnlineStatus={isOnlineStatus} readMsg={readMsg}/>
            )}
            {/*银行卡充值*/}
            {(+modelProps?.rechargeType === RechargeChannelTypeEnum.BANK_CARD ||
              channelInfo?.rechargeType ===
              RechargeChannelTypeEnum.BANK_CARD) && (
              <BankCardRechargeTopNav isOnlineStatus={isOnlineStatus} readMsg={readMsg}/>
            )}
            {/*虚拟币充值*/}
            {(+modelProps?.rechargeType ===
              RechargeChannelTypeEnum.CRYPTOCURRENCY_AUTO_CALLBACK ||
              channelInfo?.rechargeType ===
              RechargeChannelTypeEnum.CRYPTOCURRENCY_AUTO_CALLBACK) && (
              <VirtualCurrencyRechargeTopNav isOnlineStatus={isOnlineStatus} readMsg={readMsg}/>
            )}
            {(+modelProps?.rechargeType ===
              RechargeChannelTypeEnum.CRYPTOCURRENCY_UPLOAD_SCREENSHOT ||
              channelInfo?.rechargeType ===
              RechargeChannelTypeEnum.CRYPTOCURRENCY_UPLOAD_SCREENSHOT) && (
              <VirtualCurrencyRechargeTopNav isOnlineStatus={isOnlineStatus} readMsg={readMsg}/>
            )}
          </>
        )}
      </div>

      {!isOnlineStatus && (
        <div className="flex mt-[52px] flex-shrink-0 items-center justify-between px-4 h-[42px] bg-[#FFECE8]">
          <div className="flex items-center gap-2">
            <CarbonCloseFilled className="text-[red] text-[16px]" />
            <span className="text-[14px] text-[#000]">
              {t('socket连接已断开')}
            </span>
          </div>
          <div>
            <div
              onClick={() => {
                if (connectTimes >= IM_MAX_RECONNECT_COUNT) {
                  location.reload();
                  return;
                }
                if (!navigator.onLine) return toast.error(t('请打开网络'));
                connectSocket();
              }}
              className="flex h-[30px] px-2 rounded-[4px] text-[12px] gap-1 items-center justify-center  bg-[#F53F3F] text-[#fff]"
            >
              <MaterialSymbolsAutorenew />
              <span>{t('重连')}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
