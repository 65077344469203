import {useDisclosure} from '@nextui-org/react';
import BaseModal from '@/components/base/baseModal';
import {useEffect, useMemo, useState} from 'react';
import eventBus from '@/utils/evevtBus';
import {BusEnums} from '@/enums/busEnum';
import {useTranslation} from 'react-i18next';
import {useReq} from '@/services/net/request';
import {getGroupListApi} from '@/services/api/requestApi';
import C2CTopUpChat from '@/pages/onlineSupport/components/TopUp/C2CTopUpChat';
import {useOnlineSupportModel} from '@/pages/onlineSupport/useOnlineSupportModel';
import {RechargeChannelTypeEnum} from '@/enums/businessEnum';
import BankCardTopUpChat from '@/pages/onlineSupport/components/TopUp/BankCardTopUpChat';
import VirtualCurrencyUpChat from '@/pages/onlineSupport/components/TopUp/VirtualCurrencyUpChat';
import {BaseLoadingDisplay} from "@/components/base/baseLoadingDisplay";
import imSocket from "@/models/imSocket";

export default () => {
    const {t} = useTranslation();
    const {isOpen, onClose, onOpen} = useDisclosure();
    const modelProps = useOnlineSupportModel() || {};

    const [groupList, setGroupList] = useState<any>([]);

    const {run: getGroupListReq, loading} = useReq(getGroupListApi, {
        manual: true,
        onSuccess: (res: any) => {
            setGroupList(res ?? []);
        },
    });


    // 打开弹窗,获取会话列表
    const onOpenHistorySession = (data: any) => {
        setTimeout(() => {
            getGroupListReq({
                channelId: data?.id ?? modelProps?.channelId,
            });
        })
        onOpen();
    };

    useEffect(() => {
        eventBus.on(BusEnums.IM_HISTORY_SESSION_OPEN, onOpenHistorySession);
        return () => {
            eventBus.off(BusEnums.IM_HISTORY_SESSION_OPEN, onOpenHistorySession);
        };
    }, []);

    return <BaseModal
        isOpen={isOpen}
        title={t('历史会话')}
        classNames={{
            modal: '!rounded-none bg-background',
        }}
        zIndex={9999}
        onClose={onClose}
    >
        <div className="min-h-[40vh]">
            <BaseLoadingDisplay list={groupList} loading={loading && !groupList?.length}>
                <div className="min-h-[40vh]">
                    {/*C2C会话列表*/}
                    {
                        modelProps.channelInfo?.rechargeType === RechargeChannelTypeEnum.C2C_BUY && <div
                            className="min-h-[40vh] max-h-[70vh] overflow-y-auto"
                        >
                            {
                                groupList?.map?.((item: any) => {
                                    return <C2CTopUpChat item={item} onClose={onClose}/>;
                                })
                            }
                        </div>
                    }
                    {/*银行卡会话列表*/}
                    {
                        modelProps.channelInfo?.rechargeType === RechargeChannelTypeEnum.BANK_CARD && <div
                            className="min-h-[40vh] max-h-[70vh] overflow-y-auto"
                        >
                            {
                                groupList?.map?.((item: any) => {
                                    return <BankCardTopUpChat item={item} onClose={onClose}/>;
                                })
                            }
                        </div>
                    }
                    {/*虚拟币会话列表*/}
                    {
                        [RechargeChannelTypeEnum.CRYPTOCURRENCY_AUTO_CALLBACK, RechargeChannelTypeEnum.CRYPTOCURRENCY_UPLOAD_SCREENSHOT].includes(modelProps.channelInfo?.rechargeType) &&
                        <div
                            className="min-h-[40vh] max-h-[70vh] overflow-y-auto"
                        >
                            {
                                groupList?.map?.((item: any) => {
                                    return <VirtualCurrencyUpChat item={item} onClose={onClose}/>;
                                })
                            }
                        </div>
                    }
                </div>
            </BaseLoadingDisplay>
        </div>
    </BaseModal>;
}