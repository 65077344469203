import React from 'react';
import type { SVGProps } from 'react';

export function CloseIcon(props: SVGProps<SVGSVGElement>) {
  return (<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M13 24C19.0751 24 24 19.0751 24 13C24 6.92487 19.0751 2 13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24ZM9.46447 9.46447C9.78974 9.1392 10.3177 9.1392 10.643 9.46447L13 11.8215L15.357 9.46447C15.6823 9.1392 16.2103 9.1392 16.5355 9.46447C16.8608 9.78974 16.8608 10.3177 16.5355 10.643L14.1785 13L16.5355 15.357C16.8608 15.6823 16.8608 16.2103 16.5355 16.5355C16.2097 16.8614 15.6823 16.8608 15.357 16.5355L13 14.1785L10.643 16.5355C10.3171 16.8614 9.78974 16.8608 9.46447 16.5355C9.1392 16.2103 9.13861 15.6829 9.46447 15.357L11.8215 13L9.46447 10.643C9.1392 10.3177 9.13861 9.79032 9.46447 9.46447Z"
            fill="currentColor" />
    </svg>

  );
}