import {
    CompletedOrderOrderProcessEnum,
    ImMessageStatusEnum,
    ImMessageTypeEnum, RechargeChannelTypeEnum,
    SEND_MESSAGE_STATUS,
} from '@/enums/businessEnum';
import BaseLongPressMenu from '@/components/base/baseLongPressMenu';
import {toJsonData} from '@/utils/socket';
import {useTranslation} from 'react-i18next';
import {useEffect, useMemo, useRef, useState} from 'react';
import {history, useLocation, useModel} from '@@/exports';
import OrdinaryMsg from '@/pages/onlineSupport/components/Main/OrdinaryMsg';
import {PhotoView} from 'react-photo-view';
import OnlineSupportOrderMSG from '@/pages/onlineSupport/components/Main/OnlineSupportOrderMSG';
import {PhCopyFill} from '@/assets/icons/mine/PhCopyFill';
import {FormatUtils} from '@/utils/format';
import {MdiCommentQuoteOutline} from '@/assets/icons/comm/MdiCommentQuoteOutline';
import OnlineSupportInoutReply from '@/pages/onlineSupport/components/Main/OnlineSupportInoutReply';
import {IconParkSolidEditName} from '@/assets/icons/mine/IconParkSolidEditName';
import {RiArrowGoBackFill} from '@/assets/icons/comm/RiArrowGoBackFill';
import {TablerLocationCancel} from '@/assets/icons/im/TablerLocationCancel';
import {IconParkOutlineCrossRingTwo} from '@/assets/icons/im/IconParkOutlineCrossRingTwo';
import {useOnlineSupportModel} from '@/pages/onlineSupport/useOnlineSupportModel';
import {useInViewport} from 'ahooks';
import {cn, decodeHTMLEntitiesOptimized, generateRandomString, isLink} from '@/utils';
import RenderUtil from '@/utils/RenderUtil';
import ProjectSetting from '@/setting/projectSetting';
import {base64ToFile} from '@/utils/file';
import HttpSetting from '@/setting/httpSetting';
import React from 'react';

export const extractHyperlinksWithTags = (text: string): JSX.Element[] => {
    if (!text) return [];

    const urlRegex = /(https?:\/\/[^\s"'>]+)/g;
    const parts = text.split(urlRegex);

    return parts.flatMap((part, index) =>
        urlRegex.test(part) ? (
            <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="text-[#1B71EB] ">
                {part}
            </a>
        ) : (
            part.split("\n").map((line, i, arr) => (
                <React.Fragment key={`${index}-${i}`}>
                    {decodeHTMLEntitiesOptimized(line)}
                    {i < arr.length - 1 && <br/>}
                </React.Fragment>
            ))
        )
    );
};


export default ({
                    item,
                    index,
                    isMenuOpen,
                    setIsMenuOpen,
                }: {
    item: any;
    index: number;
    isMenuOpen: any;
    setIsMenuOpen: any;
}) => {
    const {
        setWhatNeedsToBeRepliedTo,
        setCurrentReplyContent,
        setCurrentEditMessage,
        setMsg,
        groupId,
        messageListRef, // 创建 ref 来引用消息容器
        setCurrentMessageListNewestId,
    } = useOnlineSupportModel() || {};
    const modelProps = useOnlineSupportModel() || {};

    const {t} = useTranslation();
    const data = toJsonData(item?.payload?.data);

    const type = item?.payload?.type ?? ImMessageTypeEnum.TEXT;
    const customerServiceName = useMemo(() => {
        if (+modelProps?.rechargeType === RechargeChannelTypeEnum.C2C_BUY && data?.c2cName) {
            return data?.c2cName;
        }
        if (data?.nickname) {
            return data?.nickname;
        }
        return data?.name
    }, [modelProps.rechargeType, data]);

    const status = item?.status;
    const isRight = item?.showDirection === 0;
    const objectItem = {...item, payload: {...item.payload, data}};
    //获取imSocket
    const {
        setImMessage,
        imSocketState,
        sendImMessage: sendImMessageSocket,
    } = useModel('imSocket');

    const {getHistoryOrderDetailById} = useModel('orderHistoryCache');
    const {state = {} as any} = useLocation();

    const {groupType = 5} = state || {};

    //根据不同类型渲染消息
    const renderIf = (type: ImMessageTypeEnum, item: any, isRight: any) => {
        // 渲染文本
        if (type === ImMessageTypeEnum.TEXT) {
            return (
                <>
                    <OrdinaryMsg isRight={isRight} item={item} type={type}>
                        <div className={cn(`rounded-md leading-[22px] font-[350]`, {
                            'text-ellipsis  pr-2 overflow-hidden whitespace-nowrap text-[14px]': item?.payload?.data?.replyId,
                            'text-imMyTextColor': item?.payload?.data?.replyId && isRight,
                            'break-words': !item?.payload?.data?.replyId,
                            'rounded-none': isLink(item?.payload?.data?.text),
                        })}
                             style={{
                                 minWidth: item?.payload?.data?.replyId ? '120px' : undefined,
                             }}
                        >
                            {item?.payload?.data?.replyId ? `${t('回复')} ${item?.payload?.data?.userInfo?.username ?? ''}` : ''}
                            {extractHyperlinksWithTags(item?.payload?.data?.text)}
                        </div>
                    </OrdinaryMsg>
                </>
            );
        }
        // 渲染图片
        if (type === ImMessageTypeEnum.IMAGE) {
            return (
                <div className="relative">
                    <OrdinaryMsg isRight={isRight} item={item} type={type}>
                        <div className="rounded-md   break-words relative">
                            <PhotoView src={item?.payload?.data?.url}>
                                <div className={cn('w-[110px] h-[110px] object-cover', {
                                    'h-[28px] w-[28px]': item?.payload?.data?.replyId,
                                })}>
                                    <img
                                        src={item?.payload?.data?.url}
                                        alt=""
                                        className={cn('w-[110px] h-[110px]  object-cover', {
                                            'h-[28px] w-[28px]': item?.payload?.data?.replyId,
                                        })}
                                    />
                                </div>
                            </PhotoView>
                        </div>
                    </OrdinaryMsg>
                </div>
            );
        }
        // 渲染视频
        if (type === ImMessageTypeEnum.VIDEO) {
            return (
                <div className="relative">
                    <OrdinaryMsg isRight={isRight} item={item} type={type}>
                        <div
                            className={`${
                                item?.payload?.data?.replyId && isRight ? '' : ''
                            } rounded-md break-words relative`}
                        >
                            <div className="w-[160px] h-[110px] object-cover">
                                <video
                                    src={item?.payload?.data?.url}
                                    controls
                                    className="w-[160px] h-[110px]  object-cover"
                                />
                            </div>
                        </div>
                    </OrdinaryMsg>
                </div>
            );
        }
        // 渲染订单
        if (type === ImMessageTypeEnum.ORDER) {
            return (
                // 根据优化需求----去除后端查不到的订单信息
                <>
                    {getHistoryOrderDetailById(
                        item?.payload?.data?.type,
                        item?.payload?.data?.id,
                    ) && (
                        <OrdinaryMsg isRight={isRight} item={item} type={type}>
                            <OnlineSupportOrderMSG
                                {...item?.payload?.data}
                                isRight={isRight}
                                isReply={item?.payload?.data?.replyId && isRight}
                            />
                        </OrdinaryMsg>
                    )}
                </>
            );
        }
        return null;
    };
    // 代理
    const uploadApiUrl_proxy = useMemo(() => {
        const storedApiUrl = localStorage.getItem('window.apiUrl');
        if (storedApiUrl) {
            return `${storedApiUrl}/app-api/infra/file/upload`;
        }
        return HttpSetting.UPLOAD_URL;
    }, [localStorage.getItem('window.apiUrl'), HttpSetting.UPLOAD_URL]);

    const {socketId} = useModel('socket');
    const {user} = useModel('user');
    const socketRef = useRef();
    useEffect(() => {
        socketRef.current = socketId;
    }, [socketId]);

    //获取卡片规定的撤回时间
    const {appInfo} = useModel('appInfo');
    //根据消息类型不同生成不同菜单
    const renderMenu = (item: any) => {
        const data = toJsonData(item?.payload?.data);
        const type = item?.payload?.type ?? ImMessageTypeEnum.TEXT;
        const status = item?.status;
        const isRight = item?.showDirection === 0;
        const objectItem = {...item, payload: {...item.payload, data}};
        //每条消息的和现在的时间差--分钟
        const timeDiff =
            (new Date().getTime() - new Date(item?.createTime).getTime()) / 1000 / 60;
        if (status === ImMessageStatusEnum.WITHDRAW_MESSAGE) {
            return [];
        }
        const menuList = [
            {
                icon: PhCopyFill,
                label: t('复制'),
                hidden: type !== ImMessageTypeEnum.TEXT || !item?.id,
                handleClick: () => {
                    if (data?.replyId) {
                        FormatUtils.copyText(data?.content?.data?.text);
                    } else {
                        FormatUtils.copyText(data?.text);
                    }
                },
            },
            {
                icon: MdiCommentQuoteOutline,
                label: t('回复'),
                hidden: !item?.id,
                handleClick: () => {
                    if (data.replyId) {
                        setCurrentReplyContent({
                            ...(item as any),
                            payload: {
                                ...item?.payload,
                                ...data?.content,
                            },
                        });
                        setWhatNeedsToBeRepliedTo(
                            <>
                                <OnlineSupportInoutReply
                                    {...{
                                        ...(item as any),
                                        payload: {
                                            ...item?.payload,
                                            ...data?.content,
                                        },
                                    }}
                                ></OnlineSupportInoutReply>
                            </>,
                        );
                    } else {
                        setCurrentReplyContent(objectItem);
                        setWhatNeedsToBeRepliedTo(
                            <>
                                <OnlineSupportInoutReply
                                    {...objectItem}
                                ></OnlineSupportInoutReply>
                            </>,
                        );
                    }
                },
            },
            {
                icon: IconParkSolidEditName,
                label: t('编辑'),
                hidden:
                    type !== ImMessageTypeEnum.TEXT ||
                    !isRight ||
                    !item?.id ||
                    data?.replyId,
                handleClick: () => {
                    setCurrentEditMessage(objectItem);
                    setMsg(data?.text);
                },
            },
            {
                icon: RiArrowGoBackFill,
                label: t('撤回'),
                //超过后端卡片超过的时间；隐藏撤回功能
                hidden:
                    !isRight ||
                    timeDiff > (appInfo as any)?.['IM_MESSAGE_REVOKE_TIME_LIMIT'] ||
                    !item?.id,
                handleClick: () => {
                    sendImMessageSocket({
                        type: 'REVOKE_MESSAGE',
                        data: {
                            groupId,
                            id: objectItem?.id,
                        },
                    } as any);
                },
            },
            // {
            //   icon: MaterialSymbolsHelpOutlineRounded,
            //   label: t('查看详情'),
            //   hidden: type !== ImMessageTypeEnum.ORDER || !item?.id,
            //   handleClick: () => {
            //     if (type === ImMessageTypeEnum.ORDER) {
            //     console.log(type,item,data,'查看详情')
            //       if (+data?.type === +CompletedOrderOrderProcessEnum.ALL) {
            //         history.push('/order/detail/' + data?.id);
            //       } else {
            //         history.push(PageEnum.TRADINGHISTORYDETAIL, {
            //           id: data?.id,
            //           type: data?.type,
            //         });
            //       }
            //     }
            //   },
            // },
            {
                icon: TablerLocationCancel,
                label: t('取消发送'),
                hidden: item?.sendStatus !== SEND_MESSAGE_STATUS.FAIL,
                handleClick: () => {
                    setImMessage((prevState: any) => {
                        return prevState?.filter((prevStateItem: any) => {
                            return (
                                toJsonData(toJsonData(prevStateItem.payload)?.data)?.uuid !==
                                toJsonData(item?.payload?.data)?.uuid
                            );
                        });
                    });
                },
            },
            {
                icon: IconParkOutlineCrossRingTwo,
                label: t('重新发送'),
                hidden: item?.sendStatus !== SEND_MESSAGE_STATUS.FAIL,
                handleClick: async () => {
                    if (imSocketState) {
                        const d = toJsonData(item?.payload?.data);
                        if (d?.imgType === 'base64' || d?.url?.length > 500) {
                            try {
                                const formData = new FormData();
                                let reqFile: any = base64ToFile(d?.url, generateRandomString(10));
                                formData.append('file', reqFile);
                                const response = await fetch(uploadApiUrl_proxy, {
                                    method: 'POST',
                                    body: formData,
                                    headers: {
                                        Authorization: `Bearer ${socketRef.current}`, // 添加 Authorization 头
                                        userId: user?.id,
                                    },
                                });
                                const data = await response.json();
                                const newImageUrl = data.data;
                                item.payload.data = JSON.stringify({
                                    ...d,
                                    imgType: 'url',
                                    url: newImageUrl,
                                });
                                sendImMessageSocket({
                                    type: 'SEND_MESSAGE',
                                    data: {
                                        ...item?.payload,
                                        groupType,
                                        channelId: modelProps?.channelId,
                                    },
                                } as any);
                            } catch (e) {
                                console.log('上传文件时失败');
                            }
                        } else {
                            sendImMessageSocket({
                                type: 'SEND_MESSAGE',
                                data: {
                                    ...item?.payload,
                                    groupType,
                                    channelId: modelProps?.channelId,
                                },
                            } as any);
                        }

                    }
                },
            },
        ];
        return menuList?.filter((item: any) => !item.hidden);
    };


    // 给后端推送消息标记已读
    const {
        readMessageId,
        setReadMessageId,
    } = useModel('imSocket');
    const messageRef = useRef(null);
    const [inViewport] = useInViewport(messageRef);

    // 发送已读消息
    const sendReadMessage = (id: string) => {
        if (!id) return;
        // 缓存已经发送过的列表
        setReadMessageId((prev: any) => {
            if (prev?.includes?.(id)) return prev;
            if (!prev?.length || !Array.isArray(prev)) {
                return [id];
            } else {
                return [...prev, id].filter((item) => item !== '');
            }
        });
    };
    useEffect(() => {
        // 可是范围内发送已读消息
        if (inViewport) {
            sendReadMessage(item?.id);
            setCurrentMessageListNewestId((prev: any) => {
                if (!prev?.length || !Array.isArray(prev)) {
                    return [item?.id];
                } else {
                    if (prev?.includes?.(item?.id)) return prev;
                    return [...prev, item?.id];
                }
            });
        } else {
            setCurrentMessageListNewestId((prev: any) => {
                return prev?.filter((id: any) => {
                    return id !== item?.id;
                });
            });
        }
    }, [inViewport, setReadMessageId]);

    return <div
        id={`message-${index}`}
        className={`pt-4 select-none`}
        key={item?.id}
        ref={index === 1 ? messageListRef : null}
    >
        <div ref={messageRef}>
            {/*加入消息*/}
            {item.isDisplayTime && <div className="text-auxiliaryTextColor text-xs text-center">
                {RenderUtil.formatDate(
                    item.createTime,
                    'YYYY-MM-DD',
                )}
            </div>}
            {type === 'JOIN_MESSAGES' && customerServiceName && (
                <div className="text-auxiliaryTextColor text-xs text-center">
                    {t('{{name}}已加入', {
                        name: customerServiceName,
                    })}
                </div>
            )}
            {/*加入消息*/}
            {type === 'FINISH_MESSAGES' && (
                <div className="text-auxiliaryTextColor text-xs text-center">
                    {data?.finishWay === 'auto'
                        ? t('系统已自动结束工单')
                        : t('{{name}}已结束工单', {
                            name: customerServiceName,
                        })}
                </div>
            )}
            {/*普通消息*/}
            {type !== 'EVALUATION' && (
                <div
                    className={`w-full mb-2   ${
                        isRight ? 'pl-[16%]' : 'pr-[16%]'
                    } ${
                        status === ImMessageStatusEnum.WITHDRAW_MESSAGE
                            ? '!px-0'
                            : ''
                    }`}
                >
                    {/*菜单包裹--长按弹窗*/}
                    <BaseLongPressMenu
                        isOpen={index === isMenuOpen}
                        onLongTouch={() => {
                            setIsMenuOpen((prevState: any) => {
                                if (+prevState !== index) {
                                    return index;
                                }
                                return prevState;
                            });
                        }}
                        setIsMenuOpen={setIsMenuOpen}
                        data={item}
                        menuClass={`!min-w-fit max-w-full ${isRight ? 'right-[34px]' : 'left-[34px]'}`}
                        menuList={renderMenu(item) as any}
                    >
                        <div onContextMenu={(e) => e.preventDefault()}>
                            {/* 消息主体 */}
                            {renderIf(type, objectItem, isRight)}
                        </div>
                    </BaseLongPressMenu>
                </div>
            )}
        </div>
    </div>;
}