import {useEffect, useMemo, useState} from "react";
import {useModel} from "@@/exports";
import {useRafInterval, useUpdateEffect} from "ahooks";
import eventBus from "@/utils/evevtBus";
import {BusEnums} from "@/enums/busEnum";
import {IM_HEART_BEAT_RECONNECT_INTERVAL, IM_MAX_RECONNECT_COUNT} from "@/models/imSocket";

export default () => {
    const [isOnline, setIsOnline] = useState(true);
    const {connect, isSub} = useModel('imSocket');
    // 当 socketId 更改时，重新连接 WebSocket
    const {socketId} = useModel('socket');
    useUpdateEffect(() => {
        setTimeout(() => {
            setIsOnline(isSub);
        }, 500);
    }, [isSub]);

    // 当前连接次数
    const [connectTimes, setConnectTimes] = useState(0);

    // 连接socket
    const connectSocket = () => {
        if (isSub || !socketId) {
            return;
        }
        try {
            if (connect && socketId) {
                connect();
                setConnectTimes((prevState) => prevState + 1);
            }
        } catch (e) {
        }
    };

    useEffect(() => {
        eventBus.on(BusEnums.RECONNECT_SOCKET, connectSocket);
        return () => {
            eventBus.off(BusEnums.RECONNECT_SOCKET, connectSocket);
        };
    }, []);

    useRafInterval(() => {
        if (connectTimes < IM_MAX_RECONNECT_COUNT) {
            connectSocket();
        }
    }, IM_HEART_BEAT_RECONNECT_INTERVAL);

    // 渲染是否在线结合是否关闭网络 socket状态
    const isOnlineStatus = useMemo(() => {
        return isOnline && navigator.onLine;
    }, [isOnline, navigator.onLine]);

    return {
        isOnlineStatus,
        connectTimes,
        connectSocket,
    }
}