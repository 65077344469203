import {SendWaiting} from '@/assets/icons/comm/SendWaiting';
import {SentSuccessfully} from '@/assets/icons/comm/SentSuccessfully';
import {
    CustomerServiceRoleEnum,
    ImMessageStatusEnum,
    RechargeChannelTypeEnum,
    SEND_MESSAGE_STATUS,
} from '@/enums/businessEnum';
import ProjectSetting from '@/setting/projectSetting';
import RenderUtil from '@/utils/RenderUtil';
import {useModel} from '@@/exports';
import {cn} from '@nextui-org/react';
import {ReactNode, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import OnlineSupportReplyTemplate from './OnlineSupportReplyTemplate';
import {useOnlineSupportModel} from '@/pages/onlineSupport/useOnlineSupportModel';

// 不同类型客服渲染头像
const renderTypeAvatar = (type: CustomerServiceRoleEnum, transferAvatar: string, defaultAvatar: string) => {
    // if (type === CustomerServiceRoleEnum.MEMBER) {
    //   return <img src={transferAvatar}
    //               className="w-[32px] h-[32px] rounded-full mr-1" alt="" />;
    // }
    return <img src={defaultAvatar}
                className="w-[32px] h-[32px] rounded-full mr-1" alt=""/>;
};
// 不同类型客服显示不同的昵称
const renderTypeName = (type: CustomerServiceRoleEnum, transferName: string, defaultName: string) => {
    if (type === CustomerServiceRoleEnum.MEMBER) {
        return <span className="ml-1 text-xs ">{defaultName}-{transferName}</span>;

    }
    return <span className="ml-1 text-xs ">{defaultName}</span>;
};

export default function OrdinaryMsg({
                                        isRight,
                                        item,
                                        children,
                                        isShowAvatar = true,
                                        isReply = false,
                                    }: IProps) {
    const {t} = useTranslation();
    const {
        imSocketState,
        sendImMessage: sendImMessageSocket,
    } = useModel('imSocket');
    const modelProps = useOnlineSupportModel() || {};
    const {appInfo} = useModel('appInfo');
    const {getUserInfoCustomerService} = useModel('cacheAvatarList');
    const userInfo = getUserInfoCustomerService(item?.userInfo?.id, item?.groupId);

    //撤回消息
    if (item.status === ImMessageStatusEnum.WITHDRAW_MESSAGE) {
        return (
            <>
                {isRight ? (
                    <div className="flex justify-center text-xs">
                        <div>
              <span>
                {RenderUtil.formatDate(item.createTime, 'YYYY-MM-DD')}&nbsp;
                  {t('你撤回了一条消息')}
              </span>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center text-xs">
            <span>
              {RenderUtil.formatDate(item.createTime, 'YYYY-MM-DD')}&nbsp;
                {t('对方撤回了一条消息')}
            </span>
                    </div>
                )}
            </>
        );
    }

    return (
        <div>
            {/*  右边的 消息 */}
            {isRight ? (
                <div>
                    {
                        !isReply && <div className="mb-1 text-auxiliaryTextColor text-right">

                            {/*<span*/}
                            {/*  className="text-[14px] ml-1">{user?.realname ?? user?.name ?? user?.email ?? user?.account}</span>*/}
                        </div>
                    }
                    <div
                        className={`flex items-center relative justify-end w-full text-imMyTextColor`}
                    >
                        {item.status === ImMessageStatusEnum.EDIT_MESSAGE && (
                            <span className="text-xs text-auxiliaryTextColor flex-shrink-0 mr-2">
                （{t('已编辑')}）
              </span>
                        )}
                        {item?.sendStatus === SEND_MESSAGE_STATUS.FAIL && (
                            <div
                                className="flex w-[50px] items-center mr-2"
                                onClick={() => {
                                    if (imSocketState) {
                                        sendImMessageSocket({
                                            type: 'SEND_MESSAGE',
                                            data: {...item, sendStatus: SEND_MESSAGE_STATUS.WAIT},
                                        } as any);
                                    }
                                }}
                            >
                                <div
                                    className="mx-1 text-backContrastColor rounded-full text-xs flex justify-center items-center w-[18px] h-[18px] bg-errorColor">
                                    !
                                </div>
                            </div>
                        )}
                        <div className={cn(`bg-primary/45 rounded-md max-w-full flex items-center justify-between`, {
                            '!bg-[transparent]': isReply,
                            ' p-2 ': !item?.payload?.data?.replyId && !isReply,
                            'pl-4 py-2 ': item?.payload?.data?.replyId,
                        })}>
                            <div
                                className={cn(
                                    'max-w-[calc(100%-20px)]',
                                )}
                            >
                                {!item?.payload?.data?.replyId && children}
                                {item?.payload?.data?.replyId && (
                                    <OnlineSupportReplyTemplate
                                        isRight={isRight}
                                        content={item?.payload?.data?.content}
                                        nickName={item?.payload?.data?.userInfo?.username}
                                        createTime={RenderUtil.formatDate(
                                            item?.payload?.data?.createTime,
                                        )}
                                    >
                                        {children}
                                    </OnlineSupportReplyTemplate>
                                )}
                            </div>
                            {/*没有id证明是前端自己推入的消息，socket还没有推送展示loading效果*/}
                            {item?.sendStatus === SEND_MESSAGE_STATUS.WAIT &&
                                !item?.payload?.data?.replyId && (
                                    <SendWaiting className="flex-shrink-0 ml-1  text-imMyTextColor"/>
                                )}

                            {![SEND_MESSAGE_STATUS.WAIT, SEND_MESSAGE_STATUS.FAIL].includes(
                                    item?.sendStatus,
                                ) &&
                                !item?.payload?.data?.replyId && (
                                    <SentSuccessfully className="flex-shrink-0 ml-1  text-imMyTextColor"/>
                                )}
                        </div>
                    </div>
                    <div className="text-right mt-1 text-auxiliaryTextColor text-[12px]  ">
                        {RenderUtil.formatDate(
                            item.createTime,
                            'HH:mm',
                        )}
                    </div>
                </div>
            ) : (
                // 左边的 消息
                <div className="max-w-full  flex items-start">
                    <>
                        {
                            !isReply && <div className="mt-1 flex-shrink-0">
                                {/*c2c头像*/}
                                {
                                    modelProps.channelInfo?.rechargeType === RechargeChannelTypeEnum.C2C_BUY && modelProps?.channelId && <>
                                        {
                                            renderTypeAvatar(+userInfo?.type, userInfo?.avatar, modelProps.shopInfo?.businessAvatar)
                                        }
                                    </>
                                }
                                {/*普通头像*/}
                                {
                                    !modelProps?.channelId &&
                                    <>
                                        {
                                            renderTypeAvatar(+userInfo?.type, userInfo?.avatar, require('@/assets/img/im/officialImAvatar.png'))
                                        }
                                    </>
                                }
                            </div>
                        }
                    </>
                    <div className="flex-1 max-w-full">
                        {!isReply && <div className="mb-1 text-auxiliaryTextColor">
                            {
                                modelProps.channelInfo?.rechargeType === RechargeChannelTypeEnum.C2C_BUY && modelProps?.channelId &&
                                <>
                                    {
                                        renderTypeName(+userInfo?.type, userInfo?.nickName, modelProps.shopInfo?.merchant)
                                    }
                                </>
                            }
                            {
                                !modelProps?.channelId &&
                                <>
                                    {
                                        renderTypeName(+userInfo?.type, userInfo?.nickName, t('{{name}}官方客服', {
                                            name: appInfo?.APP_BASE_NAME,
                                        }))
                                    }
                                </>
                            }
                        </div>}
                        <div className={`text-backContrastColor`}>
                            {/*{isShowAvatar && (*/}
                            {/*  <span className="w-[30px] flex-shrink-0 mr-2">*/}
                            {/*  <Image*/}
                            {/*    className="w-[26px] h-[26px] flex-shrink-0 rounded-full object-cover"*/}
                            {/*    src={item?.userInfo?.avatar}*/}
                            {/*  />*/}
                            {/*</span>*/}
                            {/*)}*/}
                            <div>
                                <div
                                    className={`max-w-full break-words ${
                                        isReply ? '' : 'bg-backgroundAuxiliaryColor'
                                    }  rounded-md inline-block break-words ${
                                        isShowAvatar ? 'p-2' : 'p-0'
                                    }`}
                                >
                                    {!item?.payload?.data?.replyId && children}
                                    {item?.payload?.data?.replyId && (
                                        <OnlineSupportReplyTemplate
                                            content={item?.payload?.data?.content}
                                            nickName={item?.payload?.data?.userInfo?.username}
                                            createTime={RenderUtil.formatDate(
                                                item?.payload?.data?.createTime,
                                            )}
                                        >
                                            {children}
                                        </OnlineSupportReplyTemplate>
                                    )}
                                </div>
                            </div>
                            {item.status === ImMessageStatusEnum.EDIT_MESSAGE && (
                                <span className="text-xs text-backContrastColor flex-shrink-0 ml-2">
                （{t('已编辑')}）
              </span>
                            )}
                        </div>
                        {
                            !isReply && <span className="text-[12px] text-auxiliaryTextColor pl-1">
              {RenderUtil.formatDate(
                  item.createTime,
                  'HH:mm',
              )}
            </span>
                        }
                    </div>
                </div>
            )}
        </div>
    );
}

interface IProps {
    isRight: boolean;
    item: any;
    children: ReactNode;
    type: string;
    isShowAvatar?: boolean;
    isReply?: boolean;
}
