import BaseTopNav from '@/components/base/baseTopNav';
import {cn} from '@/utils';
import {SvgSpinnersBarsRotateFade} from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import {useTranslation} from 'react-i18next';
import {useModel} from '@@/exports';
import {useOnlineSupportModel} from '@/pages/onlineSupport/useOnlineSupportModel';

// 官方客服显示头部组件
export default ({isOnlineStatus}: any) => {
    const {t} = useTranslation();
    const {appInfo} = useModel('appInfo');
    const modelProps = useOnlineSupportModel() || {};
    const {isMobile} = useModel("system")

    return <BaseTopNav
        title={
            <div className={cn(`flex gap-2 justify-center items-center`,{
                "py-2":!isMobile
            })}>
              <span>{t('{{name}}官方客服', {
                  name: appInfo?.APP_BASE_NAME,
              })}</span>
                <div
                    className={cn(`w-[6px] h-[6px] rounded-full`, {
                        'bg-errorColor': !isOnlineStatus,
                        'bg-successColor': isOnlineStatus,
                    })}
                ></div>
            </div>
        }
        leftNode={isMobile ? undefined : <></>}
        rightNode={
            isMobile ? <div className="mr-3 w-8 h-6 ">
                {/*下拉时loading组件*/}
                {(modelProps.loading || modelProps.isShowLoading) && <SvgSpinnersBarsRotateFade/>}
            </div> : null
        }
    />;
}