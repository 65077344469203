import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import PullToRefresh from '@/components/base/basePullToRefresh';
import { FeaturesDateTime } from '@/components/features/featuresDateTime';
import { CompletedOrderOrderProcessEnum } from '@/enums/businessEnum';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import FeaturesFundingRecordsCard from '@/pages/funds/orderHistory/index/components/FeaturesFundingRecordsCard';
import useConstants from '@/pages/funds/orderHistory/index/useConstants';
import { fundsListApi } from '@/services/api/requestApi';
import { FormatUtils } from '@/utils/format';
import useUrlState from '@ahooksjs/use-url-state';
import { Button, Divider, useDisclosure } from '@nextui-org/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseSift from '@/components/base/baseSift';
import OrderHistorySift from '@/pages/funds/orderHistory/index/components/OrderHistorySift';
import { AntDesignFilterFilled } from '@/assets/icons/funds/AntDesignFilterFilled';

export default function ImFundingRecordsList({ sendFn }: any) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { directionOption } = useConstants();
  const [urlState] = useUrlState<any>({});
  const { walletType, coinId } = urlState || {};

  const [direction, setDirection] = useState(directionOption[0]);
  const [sourceType, setSourceType] = useState({
    text: t('全部'),
    value: undefined,
  });
  const [time, setTime] = useState<string[]>([]);
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  // 请求参数(依赖更新,分页hooks会自动发起请求)
  const params = useMemo(() => {
    return {
      sourceType: sourceType?.value, //操作类型
      direction: direction?.value,
      startTime: time.length > 0 ? encodeURI(time[0] + 'T00:00:00') : undefined,
      endTime: time.length > 0 ? encodeURI(time[1] + 'T23:59:59') : undefined,
      walletType,
      coinId,
      sourceCoinId: coinId,
      ...pageParams,
    };
  }, [sourceType, time, walletType, coinId, pageParams, direction]);

  const { list, loading, loadMore, error, total, ...fundingRecordsPageProps } =
    usePaginationPage({
      fn: fundsListApi,
      params,
      setParams: setPageParams,
    });
  return (
    <div>
      <div className="flex justify-between relative pb-4">
        <div className="flex">
          <BaseSift
            options={directionOption}
            onChange={setDirection as any}
            current={direction}
            title={t('方向')}
          />
          <OrderHistorySift
            title={t('来源')}
            onChange={(e: any) => {
              setSourceType(e);
            }}
            current={sourceType}
          />
        </div>
        <AntDesignFilterFilled
          className="text-iconFontColor sm:cursor-pointer text-base absolute right-0"
          onClick={onOpen}
        />
      </div>
      {/*列表*/}
      <div className="pt-[10px]">
        <PullToRefresh
          loadMore={loadMore}
          total={total}
          list={list}
          error={error}
          loading={loading}
          {...fundingRecordsPageProps}
        >
          {list.map((item: any) => {
            return (
              <div key={item?.id}>
                <FeaturesFundingRecordsCard item={item}>
                  {sendFn && (
                    <div className="pb-4 mb-4 border-b-1 border-[#86909C]">
                      <div className="flex justify-between mt-2 text-xs text-right">
                        <span className=" text-auxiliaryTextColor">
                          {t('订单号')}
                        </span>
                        <span className="text-auxiliaryTextColor flex items-center">
                          {item?.orderInfo?.no}{' '}
                          <PhCopyFill
                            onClick={() =>
                              FormatUtils.copyText(item?.orderInfo?.no)
                            }
                            className="text-base ml-1"
                          />
                        </span>
                      </div>
                      <Button
                        className="mainColorButton !h-[30px] !py-0 mt-2"
                        onClick={() => {
                          sendFn({
                            type: CompletedOrderOrderProcessEnum.ALL,
                            id: item?.id,
                          });
                        }}
                      >
                        {t('发送')}
                      </Button>
                    </div>
                  )}
                  {!sendFn && (
                    <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
                  )}
                </FeaturesFundingRecordsCard>
              </div>
            );
          })}
        </PullToRefresh>
      </div>
      {/*时间弹窗*/}
      <FeaturesDateTime
        open={isOpen}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={(value: any) => {
          const { start, end } = value;
          setTime([start, end]);
          onClose();
        }}
      />
    </div>
  );
}
