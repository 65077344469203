import type { SVGProps } from 'react';

export function FluentEmojiLaugh20Regular(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.1362 8.71812C22.5826 7.40895 21.7902 6.23266 20.7787 5.22125C19.7698 4.2123 18.5935 3.41745 17.2819 2.86376C15.926 2.29038 14.4839 2 13 2C11.5161 2 10.074 2.29038 8.71812 2.86376C7.40895 3.41745 6.23266 4.20984 5.22125 5.22125C4.2123 6.2302 3.41745 7.40649 2.86376 8.71812C2.29038 10.074 2 11.5161 2 13C2 14.4839 2.29038 15.926 2.86376 17.2819C3.41745 18.5911 4.20984 19.7673 5.22125 20.7787C6.2302 21.7877 7.40649 22.5825 8.71812 23.1362C10.074 23.7096 11.5161 24 13 24C14.4839 24 15.926 23.7096 17.2819 23.1362C18.5911 22.5825 19.7673 21.7902 20.7787 20.7787C21.7877 19.7698 22.5826 18.5935 23.1362 17.2819C23.7096 15.926 24 14.4839 24 13C24 11.5136 23.7096 10.074 23.1362 8.71812ZM16.4624 9.47114C17.2056 9.47114 17.8085 10.074 17.8085 10.8172C17.8085 11.5604 17.2056 12.1633 16.4624 12.1633C15.7192 12.1633 15.1163 11.5604 15.1163 10.8172C15.1163 10.074 15.7192 9.47114 16.4624 9.47114ZM9.5302 9.47114C10.2734 9.47114 10.8763 10.074 10.8763 10.8172C10.8763 11.5604 10.2734 12.1633 9.5302 12.1633C8.78702 12.1633 8.18412 11.5604 8.18412 10.8172C8.18412 10.074 8.78456 9.47114 9.5302 9.47114ZM17.7888 17.3877C17.2819 17.9414 16.679 18.4065 16.0145 18.7535C15.0917 19.2383 14.0508 19.4942 13.0025 19.4942C11.9541 19.4942 10.9132 19.2383 9.99038 18.7535C9.32595 18.4065 8.72304 17.9389 8.21611 17.3877C7.93557 17.0825 7.98233 16.6002 8.31946 16.3591C8.60246 16.1573 8.99128 16.1942 9.22752 16.4501C9.62864 16.8881 10.106 17.2573 10.6327 17.5329C11.3685 17.9192 12.1682 18.1136 13.0049 18.1136C13.8416 18.1136 14.6414 17.9168 15.3772 17.5329C15.9038 17.2573 16.3812 16.8881 16.7823 16.4501C17.0161 16.1942 17.4074 16.1573 17.6904 16.3591C18.0226 16.6027 18.0669 17.0825 17.7888 17.3877Z"
        fill="currentColor" />
    </svg>

  );
}
