import BaseUpload from '@/components/base/baseUpload';
import {CurrentAccountTypeEnum, ImMessageTypeEnum} from '@/enums/businessEnum';
import {generateRandomString} from '@/utils';
import {PutObjectCommand, S3Client} from '@aws-sdk/client-s3';
import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useOnlineSupportModel} from '../../useOnlineSupportModel';
import {useLocalStorage} from '@/hooks/useStorage';
import CacheEnum from '@/enums/cacheEnum';

export default function () {
    const {t} = useTranslation();
    const [userType] = useLocalStorage({
        key: CacheEnum.APP_LOGIN_TYPE,
    });

    const modelProps = useOnlineSupportModel() || {};

    // 上传视频
    const handleFileVideo = async (file: any) => {
        const worker = new Worker('/hash/index.js');
        worker.postMessage({file});
        worker.onmessage = (e) => {
            const {extension, hash, blob} = e.data;
            modelProps.uploadFilesS3Req().then(async (res: any) => {
                const s3Client = new S3Client({
                    region: '123',
                    endpoint: res.endpoint,
                    forcePathStyle: true,
                    credentials: {
                        accessKeyId: res?.accessKey,
                        secretAccessKey: res?.accessSecret,
                        sessionToken: res.sessionToken,
                    },
                });
                const command = new PutObjectCommand({
                    Bucket: res?.bucket,
                    Key: `${hash}.${extension}`,
                    Body: file,
                } as any);


                const data = await s3Client.send(command);
                if (data?.['$metadata']?.httpStatusCode === 200) {
                    modelProps.sendMsg({
                        type: ImMessageTypeEnum.VIDEO,
                        data: {
                            url: `${res.endpoint}/${res?.bucket}/${hash}.${extension}`,
                        },
                    });
                }
            });
        };
    };
    // 保存uuid,确保base64预览时，和真正图片路径的uuid是一致的
    const uuid = useRef<string>();
    // 快捷菜单
    const [toolList]: any = useState(
        [
            {
                text: t('相册'),
                Icon: () => {
                    return (
                        <div>
                            <BaseUpload
                                key={'IMBaseUpload'}
                                minHeight="0"
                                isCompressed
                                currentUrls={''}
                                preview={null}
                                isBase64PreviewImg={true}
                                onChange={(url: any, type: any) => {
                                    if (type === 'isBase64PreviewImg') {
                                        uuid.current = generateRandomString(10);
                                        modelProps.sendMsg({
                                            type: ImMessageTypeEnum.IMAGE,
                                            imgType: 'base64',
                                            uuid: uuid.current,
                                            data: {
                                                url: url,
                                            },
                                        }, modelProps?.groupId);
                                        modelProps.setShowToolbar(false);
                                    } else {

                                        modelProps.sendMsg({
                                            type: ImMessageTypeEnum.IMAGE,
                                            imgType: 'url',
                                            uuid: uuid.current,
                                            data: {
                                                url: url,
                                            },
                                        }, modelProps?.groupId);
                                        uuid.current = '';
                                    }
                                }}
                            >
                                <div className="bg-background rounded-md p-2">
                                    <img
                                        className="w-[26px] h-[26px]"
                                        src={require(`@/assets/img/im/icon3.png`)}
                                    />
                                </div>
                            </BaseUpload>
                        </div>
                    );
                },
            },
            {
                text: t('视频'),
                Icon: () => {
                    return (
                        <div>
                            <BaseUpload
                                key={'IMvideoBaseUpload'}
                                fileType="video"
                                minHeight="0"
                                currentUrls={''}
                                preview={null}
                                onChange={(file: any) => {
                                    handleFileVideo(file);
                                }}
                            >
                                <div className="bg-background rounded-md p-2">
                                    <img
                                        className="w-[26px] h-[26px]"
                                        src={require(`@/assets/img/im/icon1.png`)}
                                    />
                                </div>
                            </BaseUpload>
                        </div>
                    );
                },
            },
            {
                text: t('订单发送'),
                Icon: () => (
                    <div className="bg-background rounded-md p-2">
                        <img
                            className="w-[26px] h-[26px]"
                            src={require('@/assets/img/im/icon2.png')}
                        />
                    </div>
                ),
                onclick() {
                    modelProps.onClose();
                    modelProps.onClose3();
                    modelProps.onOpen2();
                },
                hidden: CurrentAccountTypeEnum.ANONYMOUS_USER !== userType
            },
        ]?.filter((i: any) => (!!i && i?.hidden !== false)),
    );

    return {
        handleFileVideo,
        toolList,
    };
}
