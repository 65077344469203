import {ImMessageTypeEnum} from '@/enums/businessEnum';
import {cn, isLink} from '@/utils';
import {ReactNode, useMemo} from 'react';
import {PhotoView} from 'react-photo-view';
import OrdinaryMsg from './OrdinaryMsg';
import OnlineSupportOrderMSG from './OnlineSupportOrderMSG';
import { extractHyperlinksWithTags } from '@/pages/onlineSupport/components/Main/MsgItem';

export default ({children, content, isRight}: IProps) => {

    const renderIf = (type: ImMessageTypeEnum, item: any, isRight: any) => {
        // 渲染文本
        if (type === ImMessageTypeEnum.TEXT) {
            return (
              <OrdinaryMsg
                isRight={isRight}
                item={item}
                type={type}
                isShowAvatar={false}
                isReply={true}
              >
                  <div
                    className={cn(
                      'p-1 bg-[transparent] rounded-md break-words text-backContrastColor',
                      {
                          'text-imMyTextColor text-left': isRight,
                      },
                    )}
                  >
                      {extractHyperlinksWithTags(item?.payload?.data?.text)}
                  </div>
              </OrdinaryMsg>
            );
        }
        // 渲染图片
        if (type === ImMessageTypeEnum.IMAGE) {
            return (
                <div className="relative">
                    <OrdinaryMsg
                        isRight={isRight}
                        item={item}
                        type={type}
                        isShowAvatar={false}
                        isReply={true}
                    >
                        <div className="p-1 bg-[transparent] rounded-md break-words relative">
                            <PhotoView src={item?.payload?.data?.url}>
                                <img
                                    src={item?.payload?.data?.url}
                                    alt=""
                                    className="h-[100px] w-full object-cover"
                                />
                            </PhotoView>
                        </div>
                    </OrdinaryMsg>
                </div>
            );
        }
        // 渲染视频
        if (type === ImMessageTypeEnum.VIDEO) {
            return (
                <div className="relative">
                    <OrdinaryMsg
                        isRight={isRight}
                        item={item}
                        type={type}
                        isShowAvatar={false}
                        isReply={true}
                    >
                        <div className="p-1 bg-[transparent] rounded-md break-words relative">
                            <div className="rounded-md break-words relative">
                                <video
                                    src={item?.payload?.data?.url}
                                    controls
                                    className="h-[100px] w-full object-cover"
                                />
                            </div>
                        </div>
                    </OrdinaryMsg>
                </div>
            );
        }
        // 渲染订单
        if (type === ImMessageTypeEnum.ORDER) {
            return (
                <OrdinaryMsg
                    isRight={isRight}
                    item={item}
                    type={type}
                    isShowAvatar={false}
                    isReply={true}
                >
                    <div className="p-1 bg-[transparent]">
                        <OnlineSupportOrderMSG isRight={isRight} {...item?.payload?.data} />
                    </div>
                </OrdinaryMsg>
            );
        }
        return null;
    };

    return (
        <div>
            <div className={cn('flex items-center gap-1 rounded-md  relative   mt-1 box-border', {
                'text-[#fff]/80': isRight,
                'text-auxiliaryTextColor': !isRight,
            })}>
                <div className={cn(`h-[14px] w-[2px] flex-shrink-0`, {
                  ' bg-imMyTextColor': isRight,
                    ' bg-primary': !isRight,
                })}>
                </div>
                {children}
            </div>

            <div className="bg-[transparent]">
                {renderIf(
                    (content as any)?.type,
                    {payload: {data: (content as any)?.data}},
                    isRight,
                )}
            </div>
        </div>
    );
};

interface IProps {
    //回复的人的名称 -- 客服或者自己
    nickName: string;
    //创建日期
    createTime: string;
    //回复的内容
    content: string;
    //渲染回复的内容
    children: ReactNode;
    isRight?: boolean;
}
