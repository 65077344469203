export const expressionList = [
  '😀',
  '😃',
  '😄',
  '😁',
  '😆',
  '😅',
  '🤣',
  '😂',
  '🙂',
  '🙃',
  '😉',
  '😊',
  '😇',
  '🥰',
  '😍',
  '🤩',
  '😘',
  '😗',
  '😚',
  '😙',
  '😋',
  '😛',
  '😜',
  '🤪',
  '😝',
  '🤑',
  '🤗',
  '🤭',
  '🤫',
  '🤔',
  '🤐',
  '🤨',
  '😐',
  '😑',
  '😶',
  '😏',
  '😒',
  '🙄',
  '😬',
  '🤥',
  '😌',
  '😔',
  '😪',
  '🤤',
  '😴',
  '😷',
  '🤒',
  '🤕',
  '🤢',
  '🤮',
  '🤧',
  '🥵',
  '🥶',
  '🥴',
  '😵',
  '🤯',
  '🤠',
  '🥳',
  '🥸',
  '😎',
  '🤓',
  '🧐',
  '😕',
  '😟',
  '🙁',
  '☹',
  '😮',
  '😯',
  '😲',
  '😳',
  '🥺',
  '😦',
  '😧',
  '😨',
  '😰',
  '😥',
  '😢',
  '😭',
  '😱',
  '😖',
  '😣',
  '😞',
  '😓',
  '😩',
  '😫',
  '🥱',
  '😤',
  '😡',
  '😠',
  '🤬',
  '😈',
  '👿',
  '💀',
  '☠',
  '*',
  '🤡',
  '👹',
  '👺',
  '👻',
  '👽',
  '👾',
  '🤖',
  '😺',
  '😸',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙀',
  '😿',
  '😾',
  '🙈',
  '🙉',
  '🙊',
  '💋',
  '💌',
  '💘',
  '💝',
  '💖',
  '💗',
  '💓',
  '💞',
  '💕',
  '💟',
  '❣',
  '💔',
  '❤',
  '🧡',
  '💛',
  '💚',
  '💙',
  '💜',
  '🤎',
  '🖤',
  '🤍',
  '💯',
  '💢',
  '💥',
  '💫',
  '💦',
  '💨',
  '🕳',
  '💣',
  '💬',
  '👁',
  '🗨',
  '🗯',
  '💭',
  '💤',
  '🤚',
  '🖐',
  '✋',
  '🖖',
  '👌',
  '🤌',
  '🤏',
  '✌',
  '🤞',
  '🤟',
  '🤘',
  '🤙',
  '👈',
  '👉',
  '👆',
  '🖕',
  '👇',
  '☝',
  '👍',
  '👎',
  '✊',
  '👊',
  '🤛',
  '🤜',
  '👏',
  '🙌',
  '👐',
  '🤲',
  '🤝',
  '🙏',
  '✍',
  '💅',
  '🤳',
  '💪',
  '🦾',
  '🦿',
  '🦵',
  '🦶',
  '👂',
  '🦻',
  '*',
  '🧠',
  '🫀',
  '🫁',
  '🦷',
  '🦴',
  '👀',
  '👁',
  '👅',
  '👄',
  '👶',
  '🧒',
  '👦',
  '👧',
  '🧑',
  '👱',
  '👨',
  '🧔',
  '👩',
  '🧓',
  '👴',
  '👵',
  '🙍',
  '🙎',
  '🙅',
  '🙆',
  '💁',
  '🙋',
  '🧏',
  '🙇',
  '🤦',
  '🤷',
  '👮',
  '🕵',
  '💂',
  '🥷',
  '👷',
  '🤴',
  '👸',
  '👳',
  '👲',
  '🧕',
  '🤵',
  '👰',
  '🤰',
  '🤱',
  '👼',
  '🎅',
  '🤶',
  '🦸',
  '🦹',
  '🧙',
  '🧚',
  '🧛',
  '🧜',
  '🧝',
  '🧞',
  '🧟',
  '💆',
  '💇',
  '🚶',
  '🧍',
  '🧎',
  '🏃',
  '💃',
  '🕺',
  '🕴',
  '👯',
  '🧖',
  '🧗',
  '🤺',
  '🏇',
  '⛷',
  '🏂',
  '🏌',
  '🏄',
  '🚣',
  '🏊',
  '⛹',
  '🏋',
  '🚴',
  '🚵',
  '🤸',
  '🤼',
  '🤽',
  '🤾',
  '🤹',
  '🧘',
  '🛀',
  '🛌',
  '👭',
  '👫',
  '👬',
  '💏',
  '💑',
  '👪',
  '🗣',
  '👤',
  '👥',
  '🫂',
  '👣',
  '🐵',
  '🐒',
  '🦍',
  '🦧',
  '*',
  '🐕',
  '🦮',
  '🐕',
  '🐩',
  '🐺',
  '🦊',
  '🦝',
  '🐱',
  '🐈',
  '🐈',
  '🦁',
  '🐯',
  '🐅',
  '🐆',
  '*',
  '*',
  '🦄',
  '🦓',
  '🦌',
  '🦬',
  '*',
  '🐂',
  '🐃',
  '🐄',
  '🐷',
  '🐖',
  '🐗',
  '🐽',
  '🐏',
  '🐑',
  '🐐',
  '🐪',
  '🐫',
  '🦙',
  '🦒',
  '🐘',
  '🦣',
  '🦏',
  '🦛',
  '🐭',
  '🐁',
  '🐀',
  '🐹',
  '🐰',
  '🐇',
  '🐿',
  '🦫',
  '🦔',
  '🦇',
  '*',
  '*',
  '🐨',
  '🐼',
  '🦥',
  '🦦',
  '🦨',
  '🦘',
  '🦡',
  '🐾',
  '🦃',
  '🐔',
  '🐓',
  '🐣',
  '🐤',
  '🐥',
  '🐦',
  '*',
  '🕊',
  '🦅',
  '🦆',
  '🦢',
  '🦉',
  '🦩',
  '🦚',
  '🦜',
  '🐸',
  '🐊',
  '*',
  '🦎',
  '🐍',
  '🐲',
  '🐉',
  '🦕',
  '🦖',
  '🐳',
  '🐋',
  '🐬',
  '🦭',
  '🐟',
  '*',
  '🐡',
  '🦈',
  '🐙',
  '🐚',
  '🐌',
  '🦋',
  '*',
  '🐜',
  '🐝',
  '🐞',
  '🦗',
  '🕷',
  '🕸',
  '🦂',
  '🦟',
  '🦠',
  '💐',
  '🌸',
  '💮',
  '🏵',
  '🌹',
  '🥀',
  '🌺',
  '🌻',
  '🌼',
  '🌷',
  '🌱',
  '🪴',
  '🌲',
  '🌳',
  '🌴',
  '🌵',
  '🌾',
  '🌿',
  '☘',
  '🍀',
  '🍁',
  '🍂',
  '🍃',
  '🍇',
  '🍈',
  '🍉',
  '🍊',
  '🍋',
  '🍌',
  '🍍',
  '🥭',
  '🍎',
  '🍏',
  '*',
  '🍑',
  '🍒',
  '🍓',
  '🫐',
  '🥝',
  '🍅',
  '🫒',
  '🥥',
  '🥑',
  '🍆',
  '🥔',
  '🥕',
  '🌽',
  '🌶',
  '🫑',
  '🥒',
  '🥬',
  '🥦',
  '🧄',
  '🧅',
  '🍄',
  '🥜',
  '🌰',
  '🍞',
  '🥐',
  '🥖',
  '🫓',
  '🥨',
  '🥯',
  '🥞',
  '🧇',
  '🧀',
  '🍖',
  '🍗',
  '🥩',
  '🥓',
  '🍔',
  '🍟',
  '🍕',
  '🌭',
  '🥪',
  '🌮',
  '🌯',
  '🥙',
  '🧆',
  '🥚',
  '🍳',
  '🥘',
  '🍲',
  '🥣',
  '🥗',
  '🍿',
  '🧈',
  '🧂',
  '🥫',
  '🍱',
  '🍘',
  '🍙',
  '🍚',
  '🍛',
  '🍜',
  '🍝',
  '🍠',
  '🍢',
  '🍣',
  '🍤',
  '🍥',
  '🥮',
  '🍡',
  '🥟',
  '🥠',
  '🥡',
  '🦀',
  '🦞',
  '🦐',
  '🦑',
  '🦪',
  '🍦',
  '🍧',
  '🍨',
  '🍩',
  '🍪',
  '🎂',
  '🍰',
  '🧁',
  '🥧',
  '🍫',
  '🍬',
  '🍭',
  '🍮',
  '🍯',
  '🍼',
  '🥛',
  '☕',
  '🍵',
  '🍶',
  '🍾',
  '🍷',
  '🍸',
  '🍹',
  '*',
  '🍻',
  '🥂',
  '🥃',
  '🥤',
  '🧃',
  '🧉',
  '🧊',
  '🥢',
  '🍽',
  '🍴',
  '🥄',
  '🔪',
  '🏺',
  '🌍',
  '🌎',
  '🌏',
  '🌐',
  '🗺',
  '🗾',
  '🧭',
  '🏔',
  '⛰',
  '🌋',
  '🗻',
  '🏕',
  '🏖',
  '🏜',
  '🏝',
  '🏞',
  '🏟',
  '🏛',
  '🏗',
  '🧱',
  '🏘',
  '🏚',
  '🏠',
  '🏡',
  '🏢',
  '🏣',
  '🏤',
  '🏥',
  '🏦',
  '🏨',
  '🏩',
  '🏪',
  '🏫',
  '🏬',
  '🏭',
  '🏯',
  '🏰',
  '💒',
  '*',
  '🗽',
  '⛪',
  '🕌',
  '🛕',
  '🕍',
  '⛩',
  '🕋',
  '⛲',
  '⛺',
  '🌁',
  '🌃',
  '🏙',
  '🌄',
  '🌅',
  '🌆',
  '🌇',
  '🌉',
  '♨',
  '🎠',
  '🎡',
  '🎢',
  '💈',
  '🎪',
  '🚂',
  '🚃',
  '🚄',
  '🚅',
  '🚆',
  '🚇',
  '🚈',
  '🚉',
  '🚊',
  '🚝',
  '🚞',
  '🚋',
  '🚌',
  '🚍',
  '🚎',
  '🚐',
  '🚑',
  '🚒',
  '🚓',
  '🚔',
  '🚕',
  '🚖',
  '🚗',
  '🚘',
  '🚙',
  '🛻',
  '🚚',
  '🚛',
  '🚜',
  '🏎',
  '🏍',
  '🛵',
  '🦽',
  '🦼',
  '🛺',
  '🚲',
  '🛴',
  '🛹',
  '🛼',
  '🚏',
  '🛣',
  '🛤',
  '🛢',
  '⛽',
  '🚨',
  '🚥',
  '🚦',
  '🛑',
  '🚧',
  '⚓',
  '⛵',
  '🛶',
  '🚤',
  '🛳',
  '⛴',
  '🛥',
  '🚢',
  '✈',
  '🛩',
  '🛫',
  '🛬',
  '🪂',
  '💺',
  '🚁',
  '🚟',
  '🚠',
  '🚡',
  '🛰',
  '🚀',
  '🛸',
  '🛎',
  '🧳',
  '⌛',
  '⏳',
  '⌚',
  '⏰',
  '⏱',
  '⏲',
  '🕰',
  '🕛',
  '🕧',
  '🕐',
  '🕜',
  '🕑',
  '🕝',
  '🕒',
  '🕞',
  '🕓',
  '🕟',
  '🕔',
  '🕠',
  '🕕',
  '🕡',
  '🕖',
  '🕢',
  '🕗',
  '🕣',
  '🕘',
  '🕤',
  '🕙',
  '🕥',
  '🕚',
  '🕦',
  '🌑',
  '🌒',
  '🌓',
  '🌔',
  '🌕',
  '🌖',
  '🌗',
  '🌘',
  '🌙',
  '🌚',
  '🌛',
  '🌜',
  '🌡',
  '☀',
  '🌝',
  '🌞',
  '🪐',
  '⭐',
  '🌟',
  '🌠',
  '🌌',
  '☁',
  '⛅',
  '⛈',
  '🌤',
  '🌥',
  '🌦',
  '🌧',
  '🌨',
  '🌩',
  '🌪',
  '🌫',
  '🌬',
  '🌀',
  '🌈',
  '🌂',
  '☂',
  '☔',
  '⛱',
  '⚡',
  '❄',
  '☃',
  '⛄',
  '☄',
  '*',
  '💧',
  '🌊',
  '🚗',
  '🚕',
  '🚙',
  '🚌',
  '🚎',
  '🏎',
  '🚓',
  '🚑',
  '🚒',
  '🚐',
  '🚚',
  '🚛',
  '🚜',
  '🛴',
  '🚲',
  '🛵',
  '🏍',
  '🚨',
  '🚔',
  '🚍',
  '🚘',
  '🚖',
  '🚡',
  '🚠',
  '🚟',
  '🚃',
  '🚋',
  '🚞',
  '🚝',
  '🚄',
  '🚅',
  '🚈',
  '🚂',
  '🚆',
  '🚇',
  '🚊',
  '🚉',
];
