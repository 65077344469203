import useConstant from '@/pages/onlineSupport/components/Dialog/useConstant';
import { useOnlineSupportModel } from '@/pages/onlineSupport/useOnlineSupportModel';
import { memo, useMemo } from 'react';
import { useLocalStorage } from '@/hooks/useStorage';
import CacheEnum from '@/enums/cacheEnum';

export default () => {
  const modelProps = useOnlineSupportModel() || {};
  const { toolList } = useConstant() || {};

  const renderToolItem = useMemo(() => {
    return toolList.map((item: any) => {
      // 使用 React.memo 来优化 toolList 中每一项的渲染
      const ToolItem = memo(({ item }: { item: any }) => {
        return (
          <div
            key={item.text}
            onClick={() => {
              if (item?.onclick) {
                item?.onclick();
              }
            }}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
            onTouchStart={(event) => {
              event.preventDefault();
            }}
            className="w-full px-2 py-2 select-none text-center flex justify-center flex-col items-center"
          >
            <item.Icon className="text-3xl" />
            <div className="text-xs">{item.text}</div>
          </div>
        );
      });

      return <ToolItem key={item.text} item={item} />;
    });
  }, []);

  return (
    <div
      className={`grid grid-cols-4 text-auxiliaryTextColor justify-between ${
        modelProps.showToolbar ? '' : 'hidden'
      }`}
    >
      {renderToolItem}
    </div>
  );
};
