import {
  CompletedOrderOrderProcessEnum,
  FundsOrderHistoryEnum,
} from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';

import PageEnum from '@/enums/pageEnum';
import { history } from '@@/core/history';
import { useModel } from '@umijs/max';
import { useMemo } from 'react';
import ImRechargeAndCashWithdrawalCard
  from '@/pages/funds/orderHistory/index/components/ImRechargeAndCashWithdrawalCard';
import ImFundingRecordsCard from './ImFundingRecordsCard';
import useBusinessHooks from '@/hooks/useBusinessHooks';

/**
 * isRight 是否是右边的消息
 * isReply 是否是回复消息
 * isReplyBox 是否正在回复消息
 * **/
export default ({ id, type, isRight = false, isReply = false, isReplyBox = false }: IProps) => {
  const { getHistoryOrderDetailById } = useModel('orderHistoryCache');
  const { getInfoArray } = useBusinessHooks();

  const cardData = useMemo(() => {
    return getHistoryOrderDetailById(type, id);
  }, [getHistoryOrderDetailById, type, id]);
  
  return (
    <>
      {/*资金记录展示的内容*/}
      {+type === +CompletedOrderOrderProcessEnum.ALL && cardData && (
        <>
          <ImFundingRecordsCard
          clickOnTheCard={(data)=>{
             // 手续费无详情
             if (+data?.feeMark !== 0) return;
             // 闪兑跳转
             if (
               data?.sourceType ===
               FundsOrderHistoryEnum.ORDER_FLASH_EXCHANGE
             ) {
               history.push(
                 `${PageEnum.CONTRACT_ORDER_DETAIL}/${data?.sourceId}?type=flashExchange`,
               );
               return;
             }
             // 闪兑跳转
             if (
               data?.sourceType === FundsOrderHistoryEnum.CONTRACT_TRANSFER
             ) {
               history.push(
                 `${PageEnum.CONTRACT_ORDER_DETAIL}/${data?.id}?type=transfer`,
               );
               return;
             }
             // 币币订单
             if (
               [
                 FundsOrderHistoryEnum.CONTRACT_ORDER_TRADE,
                 FundsOrderHistoryEnum.CONTRACT_ORDER_TRADE_PRICE_LIMIT,
                 FundsOrderHistoryEnum.CONTRACT_TRANSFER,
               ].includes(data?.sourceType)
             ) {
               history.push(
                 `${PageEnum.CONTRACT_ORDER_DETAIL}/${data?.orderInfo?.id}?type=coin`,
               );
               return;
             }
             // 通用资金模板
             sessionStorage.setItem(
               CacheEnum.APP_FUNDS_RECORD_DETAIL,
               JSON.stringify(getInfoArray(data, true)),
             );
             history.push(
               `${PageEnum.ORDER_DETAIL}?type=recordsOfFunds&targetUrl=${
                 location.pathname + location.search
               }&noLoading=true`,
             );
          }}
            isReplyBox={isReplyBox}
            isRight={isRight}
            isReply={isReply}
            item={cardData ? cardData : {}}
          />
        </>
      )}
      {/*充值提现展示的内容*/}
      {(+type === +CompletedOrderOrderProcessEnum.ADD ||
          +type === +CompletedOrderOrderProcessEnum.SUB) &&
        cardData && (
          <ImRechargeAndCashWithdrawalCard
            isReplyBox={isReplyBox}
            isRight={isRight}
            isReply={isReply}
            item={cardData ? cardData : {}}
            clickOnTheCard={(data) => {
              history.push(PageEnum.TRADINGHISTORYDETAIL, {
                id: data.id,
                type: data?.cardType,
              });
            }}
          />
        )}
    </>
  );
};

interface IProps {
  id: any;
  //区分是资金记录还是充值和提现
  type: any;
  isRight?: boolean;
  isReply?: boolean;
  isReplyBox?: boolean;
}
