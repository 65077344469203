import { useTranslation } from 'react-i18next';
import BaseTopNav from '@/components/base/baseTopNav';
import { CustomerServiceTypeEnum, ImMessageStatusEnum, ImMessageTypeEnum } from '@/enums/businessEnum';
import { cn, findJsonValue } from '@/utils';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@@/exports';
import { useEffect, useMemo } from 'react';
import { toJsonData } from '@/utils/socket';
import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import { useLocalStorage } from '@/hooks/useStorage';
import CacheEnum from '@/enums/cacheEnum';
import eventBus from '@/utils/evevtBus';
import { BusEnums } from '@/enums/busEnum';

const MesDom = ({ payload }: any) => {
  const { t } = useTranslation();
  const data = toJsonData(payload?.data);

  return <>
    {/*非回复*/}
    {
      payload && !data?.replyId && <>
        {
          payload?.type === ImMessageTypeEnum.TEXT &&
          <span className="w-full text-[14px] text-auxiliaryTextColor break-words line-clamp-1"
          >
                    {toJsonData(payload?.data)?.text}
                  </span>
        }
        {
          payload?.type === ImMessageTypeEnum.IMAGE &&
          <span className="text-[14px] text-auxiliaryTextColor">
                    {t('[图片]')}
                  </span>
        }
        {
          payload?.type === ImMessageTypeEnum.VIDEO &&
          <span className="text-[14px] text-auxiliaryTextColor">
                    {t('[视频]')}
                  </span>
        }
        {
          payload?.type === ImMessageTypeEnum.ORDER &&
          <span className="text-[14px] text-auxiliaryTextColor">
                    {t('[订单]')}
                  </span>
        }
      </>
    }
    {/*回复*/}
    {
      data && data?.replyId && data?.content && <>
        {
          data?.content?.type === ImMessageTypeEnum.TEXT &&
          <span className="w-full text-[14px] text-auxiliaryTextColor break-words line-clamp-1">
                    {data?.content?.data?.text}
                  </span>
        }
        {
          data?.content === ImMessageTypeEnum.IMAGE &&
          <span className="text-[14px] text-auxiliaryTextColor">
                    {t('[图片]')}
                  </span>
        }
        {
          data?.content === ImMessageTypeEnum.VIDEO &&
          <span className="text-[14px] text-auxiliaryTextColor">
                    {t('[视频]')}
                  </span>
        }
        {
          data?.content === ImMessageTypeEnum.ORDER &&
          <span className="text-[14px] text-auxiliaryTextColor">
                    {t('[订单]')}
                  </span>
        }
      </>
    }
  </>;
};

const isToday = (timestamp: number) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const targetDate = new Date(timestamp);

  return targetDate.getFullYear() === today.getFullYear() &&
    targetDate.getMonth() === today.getMonth() &&
    targetDate.getDate() === today.getDate();
};

export default () => {
  const { t } = useTranslation();
  const { isPc } = useModel('system');

  const {
    customerServiceMessageUNREAD,
    c2cServiceMessageUNREAD,
    rechargeServiceMessage,
    lastMessage,
    imGroupList,
    anonymousServiceMessageUNREAD,
    anonymousLastMsg,
  } = useModel('imSocket') || {};
  const { gotoC2CCustomerService, gotoCustomerService } = useModel('imSocket');

  // 背景颜色
  const bgMap = {
    [CustomerServiceTypeEnum.C2C_CUSTOMER_SERVICE]: 'bg-[#00B42A]',
    [CustomerServiceTypeEnum.BANK_CARD]: 'bg-[#165DFF]',
    [CustomerServiceTypeEnum.CRYPTOCURRENCY_AUTO_CALLBACK]: 'bg-[#FF7D00]',
    [CustomerServiceTypeEnum.CRYPTOCURRENCY_UPLOAD_SCREENSHOT]: 'bg-[#FF7D00]',
  };

  // 对应文字
  const textMap = {
    [CustomerServiceTypeEnum.C2C_CUSTOMER_SERVICE]: t('C2C'),
    [CustomerServiceTypeEnum.BANK_CARD]: t('银行卡'),
    [CustomerServiceTypeEnum.CRYPTOCURRENCY_AUTO_CALLBACK]: t('虚拟币'),
    [CustomerServiceTypeEnum.CRYPTOCURRENCY_UPLOAD_SCREENSHOT]: t('虚拟币'),
  };


  // 获取普通客服最新消息
  const getLastMessage = (lasMsg: any) => {
    if (lastMessage === false) {
      return lasMsg;
    }
    return lastMessage;
  };

  // 获取匿名客服最新消息
  const getAnonymousLastMsg = (lasMsg: any) => {

    if (anonymousLastMsg === false || lasMsg?.offset >= anonymousLastMsg.offset) {
      return lasMsg;
    }
    return anonymousLastMsg;
  };

  // 筛出系统客服
  const systemCustomerService = useMemo(() => {
    let find: any = imGroupList?.find?.((item: any) => {
      return [CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE, CustomerServiceTypeEnum.TEMPORARY_CUSTOMER_SERVICE].includes(item?.type);
    });
    if (find) {
      let msg: any = {};
      let socketUNREAD: any = false;
      if (find?.type === CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE) {
        msg = getLastMessage(find?.messageResp);
        socketUNREAD = customerServiceMessageUNREAD;
      } else {
        msg = getAnonymousLastMsg(find?.messageResp);
        socketUNREAD = anonymousServiceMessageUNREAD;
      }

      find = {
        ...find,
        messageResp: {
          ...(msg ?? {}),
          payload: toJsonData(msg?.payload),
        },
        unreadCount: socketUNREAD === false ? find?.unreadCount : +socketUNREAD,
      };
    }

    return find;
  }, [imGroupList, lastMessage, anonymousLastMsg, customerServiceMessageUNREAD, anonymousServiceMessageUNREAD]);


  // 筛选出三方客服
  const thirdCustomerService = useMemo(() => {
    return imGroupList?.filter?.((item: any) => {
      return item?.type === CustomerServiceTypeEnum.THIRD_PARTY_CUSTOMER_SERVICE;
    });
  }, [imGroupList]);


  // 充值客服获取未读数
  const getTopUpUnread = (channel: string, unread: number) => {
    if (c2cServiceMessageUNREAD[channel] === undefined) {
      return unread;
    }
    return c2cServiceMessageUNREAD[channel];
  };

  // 获取充值客服最新消息
  const getTopUpLastMessage = (channel: string, lasMsg: any) => {
    if (rechargeServiceMessage[channel] === undefined) {
      return lasMsg;
    }
    return rechargeServiceMessage[channel];
  };


  // 筛选出非系统客服
  const customerServiceList = useMemo(() => {
    let list = imGroupList?.filter?.((item: any) => {
      return ![CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE, CustomerServiceTypeEnum.THIRD_PARTY_CUSTOMER_SERVICE, CustomerServiceTypeEnum.TEMPORARY_CUSTOMER_SERVICE].includes(item?.type);
    });

    if (list && list?.length > 0) {
      list = list.map((item: any) => {
        const msg = getTopUpLastMessage(item?.groupSign, item?.messageResp);

        const data = {
          ...item,
          messageResp: {
            ...(msg ?? {}),
            payload: toJsonData(msg?.payload),
          },
          unreadCount: getTopUpUnread(item?.groupSign, item?.unreadCount),
        };

        if (data?.type === CustomerServiceTypeEnum.C2C_CUSTOMER_SERVICE) {
          data.imgUrl = findJsonValue('businessAvatar', item);
          data.title = findJsonValue('merchant', item);
        }

        return data;
      })?.sort((a: any, b: any) => (b?.messageResp?.createTime ?? 0) - (a?.messageResp?.createTime ?? 0));
    }

    return list;
  }, [imGroupList, c2cServiceMessageUNREAD, rechargeServiceMessage]);


  // 通道id,发送信息等缓存信息
  const [imParmaData, setImParmaData] = useLocalStorage({
    key: CacheEnum.APP_IM_DATA,
    defaultValue: [],
  });


  return <div>

    {/*如果是isPc 为真 隐藏BaseTopNav组件*/}
    {!isPc && <BaseTopNav title={t('客服列表')} />}
    {/*如果是isPc 添加客服列表这个头部标题*/}
    {isPc && <div className={cn('text-[#333333] text-[16px] font-bold px-4 py-4 text-center')}>
      {t('客服列表')}
    </div>}

    <>
      <div className="sm:px-2">
        <div className={cn(`mt-2   pb-4 `, {
          'cursor-pointer hover:bg-[#F7F8FA] dark:hover:bg-backgroundAuxiliaryColor pt-4 mb-2 rounded-md': isPc,
          '!bg-[#00C4DE]/10': isPc && (imParmaData?.groupType === undefined || +imParmaData?.groupType === 5),
        })}>
          <div className="px-4 flex items-start justify-between" onClick={() => {
            if (isPc) {
              setImParmaData({});
              eventBus.emit(BusEnums.CHANGE_GROUP, {
                id: undefined,
                _groupType: 5,
              });
              return;
            }
            gotoCustomerService();
          }}>
            <div className="w-[80%] pr-4 flex items-center gap-2">
              <img src={require('@/assets/img/im/officialImAvatar.png')}
                   className="w-[40px] flex-shrink-0 h-[40px] rounded-full object-cover" alt="" />
              <div className=" w-[calc(100%-40px)]  h-full flex flex-col justify-between">
                <span className="text-[16px] text-backContrastColor mb-1">{t('平台客服')}</span>
                {
                  !systemCustomerService?.messageResp?.id &&
                  <div className="text-auxiliaryTextColor text-[14px] break-words line-clamp-1">
                    --
                  </div>
                }
                {
                  systemCustomerService?.messageResp?.id &&
                  <div className="text-auxiliaryTextColor text-[14px] break-words line-clamp-1">
                    {systemCustomerService?.messageResp?.status !== ImMessageStatusEnum.WITHDRAW_MESSAGE
                      ? <MesDom payload={systemCustomerService?.messageResp?.payload} />
                      : (+systemCustomerService?.messageResp?.showDirection === 0 ? t('你撤回了一条消息') : t('对方撤回了一条消息'))}
                  </div>
                }
              </div>
            </div>
            <div
              className="w-[20%] pb-1 h-[40px] flex-shrink-0 flex flex-col justify-between items-end">
                                 <span className="text-[12px] text-auxiliaryTextColor mb-1">  {RenderUtil.formatDate(
                                   systemCustomerService?.messageResp?.createTime,
                                   isToday(systemCustomerService?.messageResp?.createTime) ? 'HH:mm' : 'MM-DD',
                                 )}</span>
              {
                systemCustomerService?.unreadCount > 0 &&
                <span
                  className="text-xs bg-errorColor text-white rounded-full flex items-center justify-center w-fit min-w-[16px] h-[16px] px-[4px]">
                                     {systemCustomerService?.unreadCount <= 99 ? systemCustomerService?.unreadCount : '99+'}
                                  </span>
              }
            </div>
          </div>
        </div>
        {
          thirdCustomerService?.map?.((item: any) => {
            return (
              <div className="px-4 mt-4 mb-4 flex items-start justify-between" onClick={() => {
                window.open(item?.linkUrl);
              }}>
                <div className="w-[80%] pr-4 flex items-center gap-2">
                  <img src={item?.imgUrl}
                       className="w-[40px] flex-shrink-0 h-[40px] rounded-full object-cover"
                       alt="" />
                  <div className=" w-[calc(100%-40px)]  h-full flex  items-center  justify-between">
                                            <span
                                              className="text-[16px] text-backContrastColor mb-1">{item?.title}</span>
                    <div
                      className={cn(`flex-shrink-0 whitespace-nowrap ml-2 h-[16px] py-[1px] !w-fit bg-backgroundAuxiliaryColor 
                         text-foreground text-[12px] px-1 rounded-md`)}>{t('三方客服')}</div>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
      <div className="h-[1px] bg-borderColor w-full"></div>
      <div className="text-[12px] px-4 pt-[16px] text-auxiliaryTextColor sm:mb-2">{t('充值客服')}</div>
      <BaseLoadingDisplay
        loading={false}
        list={customerServiceList}
      >
        <div className="sm:px-2">
          <div className="flex flex-col ">
            {
              customerServiceList?.map?.((item: any) => {
                return <div
                  className={cn(`px-4 flex items-start justify-between py-[12px] `, {
                    'cursor-pointer hover:bg-[#F7F8FA] dark:hover:bg-backgroundAuxiliaryColor pt-4 rounded-md': isPc,
                    '!bg-[#00C4DE]/10': isPc && imParmaData?.channelId === item?.groupSign,
                  })}
                  key={item?.id} onClick={() => {
                  if (isPc) {
                    setImParmaData({
                      channelId: item?.groupSign,
                      groupType: item?.type,
                    });
                    eventBus.emit(BusEnums.CHANGE_GROUP, {
                      id: item?.groupSign,
                      _groupType: item?.type,
                      _groupId: item?.id,
                    });
                    return;
                  }
                  gotoC2CCustomerService({
                    groupType: item?.type,
                    channelId: item?.groupSign,
                    rechargeType: item?.type,
                  });
                }}>
                  <div className="w-[80%] pr-4 flex items-center gap-2">
                    <img src={item?.imgUrl}
                         className="flex-shrink-0 w-[40px] h-[40px] rounded-full object-cover"
                         alt="" />
                    <div className=" h-full w-[calc(100%-40px)]  mb-1 flex flex-col justify-between">
                      <div className="flex items-center">
                          <span
                            className="max-w-[200x] !w-fit break-all line-clamp-1 break-words text-[16px] text-backContrastColor ">{item?.title}</span>
                        <div
                          className={cn(`flex-shrink-0 whitespace-nowrap ml-2 h-[16px] py-[1px] !w-fit ${bgMap[item?.type]} 
                         text-white text-[12px] px-1 rounded-md`)}>{textMap[item?.type]}</div>
                      </div>
                      {
                        !item?.messageResp?.id && <div
                          className="h-[18px] text-[14px] text-auxiliaryTextColor break-words line-clamp-1">
                          --
                        </div>
                      }
                      {
                        item?.messageResp?.id && <div
                          className="h-[18px] text-[14px] text-auxiliaryTextColor break-words line-clamp-1">
                          {
                            item?.messageResp?.status !== ImMessageStatusEnum.WITHDRAW_MESSAGE
                              ? <MesDom payload={item?.messageResp?.payload} />
                              : (+item?.messageResp?.showDirection === 0 ? t('你撤回了一条消息') : t('对方撤回了一条消息'))
                          }

                        </div>
                      }
                    </div>
                  </div>
                  <div
                    className="h-[40px] pb-1 w-[20%] flex-shrink-0 flex flex-col justify-between items-end">
                    {
                      item?.messageResp?.createTime ?
                        <span
                          className="text-[12px] text-auxiliaryTextColor mb-1">  {RenderUtil.formatDate(
                          item?.messageResp?.createTime,
                          isToday(item?.messageResp?.createTime) ? 'HH:mm' : 'MM-DD',
                        )}</span>
                        :
                        <span className="text-[14px] text-auxiliaryTextColor mb-1">{' '}</span>
                    }
                    <div className="h-[13px]">
                      {
                        item?.unreadCount > 0 &&
                        <span
                          className="text-xs bg-errorColor text-white rounded-full flex items-center justify-center w-fit min-w-[16px] h-[16px] px-[4px]">
                        {item?.unreadCount <= 99 ? item?.unreadCount : '99+'}
                         </span>
                      }
                    </div>
                  </div>
                </div>;
              })
            }
          </div>
        </div>
      </BaseLoadingDisplay>

    </>
  </div>;
}