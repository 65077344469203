import type { SVGProps } from 'react';

export function DeletePhiz(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3085_14037)">
        <path
          d="M18.0047 17.3345H7.26189C6.9811 17.334 6.70358 17.2743 6.44738 17.1594C6.19117 17.0445 5.96202 16.877 5.77483 16.6677L0.173376 10.4461C0.0627869 10.3236 0.00157166 10.1644 0.00157166 9.99931C0.00157166 9.83425 0.0627869 9.67506 0.173376 9.55252L5.77483 3.3309C5.96202 3.12162 6.19117 2.95408 6.44738 2.83919C6.70358 2.7243 6.9811 2.66463 7.26189 2.66406H18.0047C18.5353 2.66406 19.0441 2.87483 19.4193 3.25C19.7944 3.62517 20.0052 4.13401 20.0052 4.66458V15.334C20.0052 15.8646 19.7944 16.3734 19.4193 16.7486C19.0441 17.1238 18.5353 17.3345 18.0047 17.3345ZM1.56707 9.99931L6.76843 15.7808C6.83077 15.8499 6.90688 15.9052 6.99187 15.9431C7.07686 15.981 7.16883 16.0006 7.26189 16.0009H18.0047C18.1815 16.0009 18.3512 15.9306 18.4762 15.8056C18.6013 15.6805 18.6715 15.5109 18.6715 15.334V4.66458C18.6715 4.48773 18.6013 4.31811 18.4762 4.19306C18.3512 4.068 18.1815 3.99774 18.0047 3.99774H7.26189C7.16883 3.99796 7.07686 4.01766 6.99187 4.05555C6.90688 4.09345 6.83077 4.14872 6.76843 4.2178L1.56707 9.99931Z"
          fill="currentColor" />
        <path
          d="M14.6705 13.4943C14.4955 13.4936 14.3279 13.4241 14.2037 13.3009L8.53556 7.63945C8.41136 7.51451 8.34164 7.3455 8.34164 7.16933C8.34164 6.99316 8.41136 6.82414 8.53556 6.6992C8.59755 6.6367 8.6713 6.58709 8.75256 6.55324C8.83382 6.51938 8.92098 6.50195 9.00901 6.50195C9.09704 6.50195 9.1842 6.51938 9.26546 6.55324C9.34672 6.58709 9.42048 6.6367 9.48247 6.6992L15.1373 12.3607C15.2615 12.4856 15.3312 12.6546 15.3312 12.8308C15.3312 13.007 15.2615 13.176 15.1373 13.3009C15.0131 13.4241 14.8454 13.4936 14.6705 13.4943Z"
          fill="currentColor" />
        <path
          d="M9.00901 13.4937C8.83641 13.5038 8.66666 13.4464 8.53556 13.3337C8.41136 13.2087 8.34164 13.0397 8.34164 12.8635C8.34164 12.6874 8.41136 12.5184 8.53556 12.3934L14.197 6.73194C14.3237 6.64517 14.4764 6.60477 14.6294 6.61755C14.7824 6.63034 14.9263 6.69553 15.0368 6.80211C15.1473 6.9087 15.2177 7.05016 15.236 7.20261C15.2543 7.35505 15.2194 7.50913 15.1373 7.63885L9.48247 13.3337C9.35136 13.4464 9.18161 13.5038 9.00901 13.4937Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_3085_14037">
          <rect width="20" height="20" fill="transparent" />
        </clipPath>
      </defs>
    </svg>

  );
}
