import { useTranslation } from 'react-i18next';

import { Divider } from '@nextui-org/react';

import React from 'react';
import { useOnlineSupportModel } from '@/pages/onlineSupport/useOnlineSupportModel';
import { CustomerServiceTypeEnum } from '@/enums/businessEnum';

export default ({ item, onClose }: any) => {
  const { t } = useTranslation();
  const modelProps = useOnlineSupportModel() || {};


  return <>
    <div className="border-b-1 border-b-borderColor  px-4 py-6">
      <div className="text-backContrastColor my-3">
        <span className="text-[20px] ">{item?.payGateway}</span>
      </div>
      <div className="flex justify-between items-end">
        <div className="flex flex-col gap-2 text-[12px]">
          <div className="flex items-center gap-1">
            <span className="text-auxiliaryTextColor">{t('充值类型')}</span>
            <span className="text-backContrastColor">{t('银行卡充值')}</span>
          </div>
          <div className="flex items-center gap-1">
            <span className="text-auxiliaryTextColor">{t('币种')}</span>
            <div className="flex items-center gpa-1 ">
              <img src={item?.coinIcon} className="w-5 h-5 mr-1 rounded-full " alt="" />
              <span>{item?.coinName}</span>
            </div>
          </div>
        </div>
        <img src={require('@/assets/img/im/startASession.png')} className="w-[36px] h-[36px] object-cover" alt=""
             onClick={() => {
               if(modelProps.channelId === item?.id){
                 onClose?.();
                 return
               }
               modelProps.switchChat({
                 id:item?.id,
                 _groupType:item.rechargeType
               });
               onClose?.();
             }} />
      </div>
    </div>
    <Divider className="bg-borderColor" />
  </>;
}