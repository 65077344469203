import { useTranslation } from 'react-i18next';
import { Clock } from '@/assets/icons/comm/Clock';
import { Divider } from '@nextui-org/react';

import React, { useMemo } from 'react';
import { toJsonData } from '@/utils/socket';
import RenderUtil from '@/utils/RenderUtil';
import { useOnlineSupportModel } from '@/pages/onlineSupport/useOnlineSupportModel';
import { CustomerServiceTypeEnum } from '@/enums/businessEnum';

export default ({ item, onClose }: any) => {
  const { t } = useTranslation();
  const modelProps = useOnlineSupportModel() || {};

  const findJsonValue = (field: string) => {
    return item?.bankCard
      ? toJsonData(item.bankCard)?.find(
        (it: any) => it.field === field,
      )?.value
      : '';
  };
  const shopInfo = useMemo(() => {
    return {
      ...item,
      businessAvatar: findJsonValue('businessAvatar'), // 商家头像
      merchant: findJsonValue('merchant'), // 商家名称
      volume: findJsonValue('volume'), // 成交量
      orderRate: findJsonValue('orderRate'), // 成单率
      avaCoinReleaseTime: findJsonValue('avaCoinReleaseTime'), // 平均放币时间
      price: item?.rate, // 标价
      quantity: findJsonValue('quantity'), // 数量
    };
  }, [item]);


  return <>
    <div className="border-b-1 border-b-borderColor px-4 py-6">
      <div className="flex items-center gap-2">
        <img src={shopInfo?.businessAvatar} className="w-[18px] h-[18px] rounded-full object-cover" alt="" />
        <span>{shopInfo?.merchant}</span>
        <img src={require('@/assets/img/shopLogo.png')} className="w-[14px] h-[14px] object-cover" alt="" />
      </div>
      <div className="flex items-center gap-3 mt-[6px] text-[12px] text-auxiliaryTextColor">
        <div className="flex items-center gap-1">
        <span  className="border-b-1 border-b-auxiliaryTextColor border-dashed pb-[1px]">{t('成交量')}</span>
          <span>{RenderUtil.FormatAmount(shopInfo?.volume, 2)} ({shopInfo.orderRate}%)</span>
        </div>
        <div className="flex gap-1 items-center">
          <Clock />

          <span
                className="border-b-1 border-b-auxiliaryTextColor border-dashed pb-[1px]">{shopInfo?.avaCoinReleaseTime}{t('分钟')}</span>
        </div>
      </div>
      <div className="text-backContrastColor my-3">
        <span className="text-[12px]">$</span>
        <span className="text-[20px] px-1">{shopInfo?.price}</span>
      </div>
      <div className="flex justify-between items-end">
        <div className="flex flex-col gap-2 text-[12px]">
          <div className="flex items-center gap-1">
            <span className="text-auxiliaryTextColor">{t('数量')}</span>
            <span className="text-backContrastColor">{shopInfo?.quantity}{' ' + shopInfo?.coinName}</span>
          </div>
          <div className="flex items-center gap-1">
            <span className="text-auxiliaryTextColor">{t('限额')}</span>
            <span
              className="text-backContrastColor">{RenderUtil.FormatAmount(shopInfo?.minAmount)}-{RenderUtil.FormatAmount(shopInfo?.maxAmount)}{' ' + shopInfo?.quoteCoinName}</span>
          </div>
        </div>
        <img src={require('@/assets/img/im/startASession.png')} className="w-[36px] h-[36px] object-cover" alt=""
             onClick={() => {
               if (modelProps.channelId === item?.id) {
                 onClose?.();
                 return;
               }
               modelProps.switchChat({
                 id:item?.id,
                 _groupType:item.rechargeType
               });
               onClose?.();
             }} />
      </div>
    </div>
    <Divider className="bg-borderColor" />
  </>;
}