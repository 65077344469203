import type { SVGProps } from 'react';

export function BackingOut(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.30942 0.310547L8.68828 1.68941L3.37771 6.99998L8.68828 12.3105L7.30942 13.6894L0.619995 6.99998L7.30942 0.310547Z"
        fill="currentColor" fillOpacity="0.9" />
    </svg>

  );
}
