import { expressionList } from '@/pages/onlineSupport/constant';
import { useOnlineSupportModel } from '@/pages/onlineSupport/useOnlineSupportModel';
import { cn } from '@/utils';
import { DeletePhiz } from '@/assets/icons/comm/DeletePhiz';

export default () => {
  const modelProps = useOnlineSupportModel() || {};

  return <div
    className={cn('relative h-[36vh] pt-2 !bg-background overflow-y-auto sm:h-[36vh] pb-2', {
      hidden: !modelProps.isOpen3,
    })}
  >
    <div className="pb-4 grid grid-cols-7  text-foreground  h-full max-w-[100%] ">
      {expressionList?.map((item, index) => {
        return (
          <span
            key={index}
            className="text-3xl mb-2 text-center"
            onClick={() => {
              modelProps.setMsg((prev: string) => {
                return prev + item;
              });
            }}
          >
                {' '}
            {item}{' '}
              </span>
        );
      })}
    </div>
    <div className="fixed bg-background p-3 right-4 bottom-4" onClick={()=>{
      modelProps.setMsg((prev: string) => {
        return Array.from(prev).slice(0, -1).join('');
      });
    }}>
      <DeletePhiz className="font-bold"/>
    </div>
  </div>;
}