import { FluentTriangle16Filled } from '@/assets/icons/comm/FluentTriangle16Filled';
import { FluentTriangleDown12Filled } from '@/assets/icons/comm/FluentTriangleDown12Filled';
import { useClickAway, useMount, useUpdateEffect } from 'ahooks';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useModel } from '@@/exports';

export default function BaseLongPressMenu({
                                            children,
                                            menuClass = '',
                                            menuList,
                                            isOpen,
                                            onLongTouch,
                                            data,
                                            setIsMenuOpen,
                                          }: IProps) {
  //获取子元素的引用
  const childrenRef = useRef<HTMLDivElement>(null);
  //获取菜单的引用
  const menuRef = useRef<HTMLDivElement>(null);
  //菜单是否在目标元素顶部显示
  const [menuIsShowTop, setMenuIsShowTop] = useState(true);
  //定时器
  let timer: any = null;
  //是否显示菜单
  const [isShow, setIsShow] = useState(isOpen ?? false);
  useUpdateEffect(() => {
    setIsShow(isOpen as boolean);
  }, [isOpen]);
  //获取此刻事件
  const getTimeNow = () => {
    return new Date().getTime();
  };
  const mouseDown = () => {
    //获取鼠标按下开始时间
    const timeStart = getTimeNow();
    //定时器每100毫秒执行一次
    timer = setInterval(() => {
      //获取此刻时间
      const timeNow = getTimeNow();
      //获得相差时间
      const timeDifference = timeNow - timeStart;
      //如果鼠标按下时间大于100毫秒，则显示菜单
      if (timeDifference > 400) {
        if (Number(childrenRef.current?.getBoundingClientRect()?.top) < 100) {
          setMenuIsShowTop(false);
        } else {
          setMenuIsShowTop(true);
        }
        if (onLongTouch) {
          onLongTouch(data);
        } else {
          setIsShow(false);
        }
        //清楚定时器
        clearInterval(timer);
      }
    }, 100);
  };
  const mouseup = () => {
    if (timer) {
      clearInterval(timer);
    }
  };

  const scroll = () => {
    if (setIsMenuOpen) {
      setIsMenuOpen?.(-1);
    } else {
      setIsShow(false);
    }
  };
  useEffect(() => {
    //手机端
    childrenRef.current?.addEventListener('touchstart', mouseDown);
    childrenRef.current?.addEventListener('touchend', mouseup);
    //PC端
    childrenRef.current?.addEventListener('mousedown', mouseDown);
    childrenRef.current?.addEventListener('mouseup', mouseup);

    //监听页面滚动
    document.addEventListener('scroll', scroll);

    return () => {
      //手机端
      childrenRef.current?.removeEventListener('mousedown', mouseDown);
      childrenRef.current?.removeEventListener('mouseup', mouseup);
      //PC端
      childrenRef.current?.addEventListener('mousedown', mouseDown);
      childrenRef.current?.addEventListener('mouseup', mouseup);
      document.body.removeEventListener('scroll', scroll);
      clearInterval(timer);
    };
  }, []);
  const { isMobile } = useModel('system');

  //点击菜单周边关闭菜单
  useClickAway((event) => {
    if (!isMobile) return;
    if (setIsMenuOpen) {
      setIsMenuOpen?.(-1);
    } else {
      setIsShow(false);
    }
  }, menuRef);

  return (
    <div className="relative" ref={menuRef}>
      {/*菜单*/}
      {isShow && menuList?.length > 0 && (
        <>
          <div
            className={`absolute  z-[10001]  py-2 px-2
            min-w-[150px] flex flex-wrap justify-between gap-y-2 bg-backContrastColor text-background shadow-2xl  rounded-md ${menuClass} ${
              menuIsShowTop ? '-top-[64px]' : '-bottom-[64px]'
            }`}
          >
            {menuList?.map((item: any) => {
              return (
                <div
                  className="flex flex-col mx-2 flex-shrink-0 items-center text-center"
                  key={item.icon}
                  onClick={() => {
                    item.handleClick && item.handleClick();
                    setIsShow(false);
                  }}
                >
                  <item.icon />
                  <span className="text-xs mt-1">{item.label}</span>
                </div>
              );
            })}
            {menuIsShowTop && (
              <FluentTriangleDown12Filled
                className={`absolute -bottom-[14px] z-[10000] left-1/2  text-backContrastColor`}
                height="20px"
              />
            )}
            {!menuIsShowTop && (
              <FluentTriangle16Filled
                className={`absolute -top-[14px] z-[10000] left-1/2  text-backContrastColor`}
                height="20px"
              />
            )}
          </div>

        </>
      )}
      {/*内容*/}
      <div ref={childrenRef} className="disableLongPressMenu select-none" onContextMenu={(e) => {
        e.preventDefault();
      }}>{children}</div>
    </div>
  );
}

interface IProps {
  children: ReactNode;
  //菜单定位是否在左（非左即右）
  menuClass: string;
  //菜单列表
  menuList: {
    icon: any;
    label: string;
  }[];
  //外部控制是否显示
  isOpen?: boolean;
  //长按事件
  onLongTouch: (data: any) => void;
  //每一项的数据
  data: any;
  //设置当前选择菜单
  setIsMenuOpen: (data: any) => void;
}
