import type { SVGProps } from 'react';

export function SendWaiting(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="8" cy="8" r="5.25" stroke="currentColor" strokeWidth="1.5" />
      <line x1="5.75" y1="8.25" x2="10.25" y2="8.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>

  );
}
