import { ImMessageTypeEnum } from '@/enums/businessEnum';
import { Image } from '@nextui-org/react';
import OnlineSupportOrderMSG from './OnlineSupportOrderMSG';
import { useTranslation } from 'react-i18next';

export default ({ payload, ...reset }: IProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center flex-wrap gap-2 text-[14px] text-backContrastColor/90 pb-1">
      <span>
        {t('回复')}
      </span>
      <span>{reset?.userInfo?.username+'：'}</span>
      {payload?.type === ImMessageTypeEnum.TEXT && (
        <div>
          {payload?.data?.replyId
            ? payload?.data?.content?.data?.text
            : payload?.data?.text}
        </div>
      )}
      {payload?.type === ImMessageTypeEnum.IMAGE && (
        <div className="w-full">
          {payload?.data?.replyId ? (
            payload?.data?.content
          ) : (
            <>
              <Image
                className="w-[100%] h-14 flex-shrink-0 rounded-md object-cover"
                src={payload?.data?.url}
              />
            </>
          )}
        </div>
      )}
      {payload?.type === ImMessageTypeEnum.VIDEO && (
        <div className="w-full">
          {payload?.data?.replyId ? (
            payload?.data?.content
          ) : (
            <>
              <div className="rounded-md break-words relative">
                <video
                  src={payload?.data?.url}
                  height="100%"
                  className="max-h-[40px]"
                />
              </div>
            </>
          )}
        </div>
      )}
      {payload?.type === ImMessageTypeEnum.ORDER && (
        <div className="w-full">
          {payload?.data?.replyId ? (
            payload?.data?.content
          ) : (
            <div className="max-w-[90%]">
              <OnlineSupportOrderMSG {...payload?.data} isReplyBox={true} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface IProps {
  payload: any;
}
