import BaseTopNav from '@/components/base/baseTopNav';
import { cn } from '@/utils';
import { useOnlineSupportModel } from '@/pages/onlineSupport/useOnlineSupportModel';
import { useMemo } from 'react';
import { HistorySession } from '@/assets/icons/comm/HistorySession';
import { history } from '@@/core/history';
import { useTranslation } from 'react-i18next';
import { BackingOut } from '@/assets/icons/comm/BackingOut';
import eventBus from '@/utils/evevtBus';
import { BusEnums } from '@/enums/busEnum';
import {useModel} from "@@/exports";

// 官方客服显示头部组件
export default ({ isOnlineStatus,readMsg }: any) => {
  const { t } = useTranslation();
  const modelProps = useOnlineSupportModel() || {};
  const channelInfo = useMemo(() => {
    return modelProps.channelInfo;
  }, [modelProps.channelInfo]);
  const {isMobile} = useModel("system")


  return <BaseTopNav
    leftNode={
      <div className="flex items-center gap-1 p-4 select-none" >
        {
            isMobile && <BackingOut className="text-xl mr-2 font-bold" onClick={()=>{
            readMsg?.()
            history.back()
          }}/>
        }
        <div className="w-[32px] h-[32px] flex justify-center items-center">
          {
            modelProps?.channelInfo?.coinIcon && <img src={modelProps?.channelInfo?.coinIcon}
                                                      className="w-[32px] h-[32px] rounded-full object-cover" alt="" />
          }
        </div>
        <div>
          <div className="flex items-center gap-1 leading-[14px]">
            <span className="text-backContrastColor text-[14px]">{t("银行卡充值客服")}</span>
            <div
              className={cn(`w-[6px] h-[6px] rounded-full`, {
                'bg-errorColor': !isOnlineStatus,
                'bg-successColor': isOnlineStatus,
              })}
            ></div>
          </div>
          <div className="text-auxiliaryTextColor flex items-center gap-1 leading-[14px]">
            <span className=" text-[12px]">{channelInfo?.payGateway}</span>
            -
            <span className=" text-[12px]">{channelInfo?.coinName}</span>
          </div>
        </div>
      </div>
    }
    rightNode={
        isMobile ? <HistorySession className="pr-4 box-content text-backContrastColor/70" onClick={()=>{
        eventBus.emit(BusEnums.IM_HISTORY_SESSION_OPEN,{id:channelInfo?.id});
      }}/> :null
    }
    gridTemplateColumns={'1fr 0  auto'}
  />;
}