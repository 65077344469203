import { FluentEmojiLaugh20Regular } from '@/assets/icons/comm/FluentEmojiLaugh20Regular';
import { MaterialSymbolsClose } from '@/assets/icons/comm/MaterialSymbolsClose';
import { GgAdd } from '@/assets/icons/mine/GgAdd';
import BaseInput from '@/components/base/baseInput';
import { BaseModalZIndex } from '@/components/base/baseModal/Modal';
import { ImMessageTypeEnum } from '@/enums/businessEnum';
import { useModel } from '@umijs/max';
import { useMount } from 'ahooks';
import {useEffect, useMemo, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useOnlineSupportModel } from '../../useOnlineSupportModel';
import { CloseIcon } from '@/assets/icons/mine/CloseIcon';
import BottomMenu from '@/pages/onlineSupport/components/Bottom/BottomMenu';
import BottomPhiz from '@/pages/onlineSupport/components/Bottom/BottomPhiz';
import { cn } from '@/utils';
import { useLocalStorage } from '@/hooks/useStorage';
import CacheEnum from '@/enums/cacheEnum';

export default () => {
  const modelProps = useOnlineSupportModel() || {};
  const { isMobile } = useModel('system');
  const { t } = useTranslation();
  const innerWrapperRef = useRef<any>();
  const [ bottomKey,setBottomKey] = useState(1)
  // 设置底部输入框的宽度--
  const setInputBoxWidth = () => {
    if (!isMobile) {
      setTimeout(() => {
        if (modelProps.InputBoxRef?.current?.style?.width) {
          modelProps.InputBoxRef.current.style.width =
            modelProps.messageBoxRef?.current?.offsetWidth + 'px';
        }
      });
    } else {
      if (modelProps.InputBoxRef?.current?.style?.width) {
        setTimeout(() => {
          if (modelProps.InputBoxRef.current)
            modelProps.InputBoxRef.current.style.width = '';
        });
      }
    }
  };

  const getScrollTop = () => {
    let scrollTop = 0;
    if (document.documentElement && document.documentElement.scrollTop) {
      scrollTop = document.documentElement.scrollTop;
    } else if (document.body) {
      scrollTop = document.body.scrollTop;
    }
    return scrollTop;
  };

  const oldScrollTop = getScrollTop() || 0; // 记录当前滚动位置

  useMount(() => {
    setInputBoxWidth();
    document.body.addEventListener('focusin', () => {  //软键盘弹起事件
    });
    document.body.addEventListener('focusout', () => { //软键盘关闭事件
      var ua = window.navigator.userAgent;
      if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPad') > 0) { //键盘收起页面空白问题
        document.body.scrollTop = oldScrollTop;
        document.documentElement.scrollTop = oldScrollTop;
      }
    });
    document.body.addEventListener('resize', setInputBoxWidth);
    return () => {
      window.removeEventListener('resize', setInputBoxWidth);
    };
  });

  // 计算出当前输入行数
  const currentLineCount = useMemo(() => {
    // 根据高度计算出的行数
    const heightLine = Math.max(modelProps.msg?.trim?.()?.split('\n').length || 1, 1);
    // 根据宽度计算出的高度
    const totalWidth = innerWrapperRef.current?.offsetWidth || 0;
    const widthLine = Math.floor((modelProps.msg?.length * 16) / totalWidth);
    return Math.max(heightLine, widthLine);
  }, [modelProps.msg, innerWrapperRef.current]);


  return (
    <div key={bottomKey}>
      <div className="h-[76px] !bg-background  w-full flex-shrink-0">
      </div>
      <div
        className={cn('fixed  bottom-0 !bg-background  left-0 w-[100vw] pt-2 sm:absolute sm:w-full  flex items-center box-content border-t-1 border-borderColor min-h-[60px]', {
          hidden: modelProps.isOpen2,
        })}
        style={{ zIndex: BaseModalZIndex + 10 }}
      >
        <div
          ref={modelProps.InputBoxRef}
          onClick={(event) => {
            event.stopPropagation();
          }}
          className={`left-0  px-2  rounded-md w-full  `}
        >
          <div className=" relative  rounded-md">
            {modelProps.whatNeedsToBeRepliedTo && (
              <div
                className="absolute bg-background -top-6 w-full -translate-y-full  rounded-md px-1 py-1 mx-2 mt-1 box-border flex justify-between items-center ">
                <div className="line-clamp-1">
                  {modelProps.whatNeedsToBeRepliedTo}
                </div>
                <MaterialSymbolsClose
                  className="absolute top-1 right-2"
                  onClick={() => {
                    modelProps.setWhatNeedsToBeRepliedTo(null);
                    modelProps.setCurrentReplyContent(null);
                    modelProps.setCurrentEditMessage({});
                  }}
                />
              </div>
            )}
            {/*  IM 底部输入框 */}
            <div className="bg-backgroundAuxiliaryColor  rounded-md flex items-center">
              <div
                className=" flex items-center h-full pl-2 pr-1"
                onClick={(event) => {
                  if (modelProps.isOpen3) {
                    modelProps.onClose3();
                  } else {
                    modelProps.onOpen3();
                  }
                  modelProps.setShowToolbar(false);

                  event.preventDefault();
                }}
              >
                {/* 输入框左边的表情图标 */}
                {/* 弹框位置 src/pages/onlineSupport/components/Dialog/DialogGroup.tsx OnlineSupportExpressionDrawer */}
                <FluentEmojiLaugh20Regular
                  className=" text-[#4E5969] dark:text-[#4E5969] cursor-pointer"
                  width="26px"
                  height="26px"
                />
              </div>

              {/*  IM 底部输入框 */}
              {/*  IM 底部输入框 */}
              <textarea
                onFocus={() => {
                  modelProps.onClose();
                  modelProps.onClose3();
                  modelProps.onClose2();
                  modelProps.setShowToolbar(false);
                }}
                ref={innerWrapperRef}
                onKeyUp={(e) => {
                  if (e.key === 'Backspace' || e.keyCode === 8) {
                    if (modelProps.msg === '') {
                      modelProps.setWhatNeedsToBeRepliedTo(null);
                      modelProps.setCurrentReplyContent(null);
                      modelProps.setCurrentEditMessage({});
                    }
                  }
                  if (e.key === 'Enter' && modelProps.msg.trim() !== '') {
                    if (!isMobile) {
                      if(e.altKey){
                        modelProps.setMsg(modelProps.msg + '\n');
                        return
                      }
                        modelProps.sendMsg({
                        type: ImMessageTypeEnum.TEXT,
                        id: modelProps.currentEditMessage?.id ?? undefined,
                        uuid: modelProps.currentEditMessage?.uuid ?? undefined,
                        data: {
                          text: modelProps.msg,
                        },
                      });
                      setBottomKey(prevState => prevState+1)
                      e.preventDefault();
                    }
                  }
                }}
                value={modelProps.msg}
                onChange={(e) => modelProps.setMsg(e.target.value)}
                placeholder={t('输入你的问题')}
                className="outline-none flex pt-[10px] items-center !bg-backgroundAuxiliaryColor resize-none text-sm h-[40px] max-h-[200px] my-1 leading-[20px] w-full px-2   rounded-md box-border"
                style={{
                  height: Math.min(Math.max(40, currentLineCount * 20), 200),
                  transition: 'height 0.2s ease-out',
                }}
              />


              {/*  IM 输入框 右边的 */}
              <div className="flex items-center flex-shrink-0 h-[50px] " tabIndex={-1}>
                {modelProps.msg ? (
                  //  一个发送按钮图标
                  <img
                    src={require('@/assets/img/im/sendIcon.png')}
                    className="mx-2 w-[32px] h-[32px] flex-shrink-0 rounded-full  cursor-pointer"
                    onClick={(event) => {
                      if (modelProps.msg.trim() === '') return;
                      modelProps.sendMsg({
                        type: ImMessageTypeEnum.TEXT,
                        id: modelProps.currentEditMessage?.id ?? undefined,
                        uuid:
                          modelProps.currentEditMessage?.payload?.uuid ??
                          undefined,
                        data: {
                          text: modelProps.msg,
                        },
                      });
                      setBottomKey(prevState => prevState+1)
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                    onMouseDown={(event) => {
                      // 阻止 Safari 默认的焦点切换行为
                      event.preventDefault();
                    }}
                    onTouchStart={(event) => {
                      // 处理触摸设备上的焦点问题
                      event.preventDefault();
                    }}
                  />
                ) : (
                  //  一个菜单抽屉按钮
                  <div
                    className="flex items-center  h-full pl-1 pr-2  "
                    onClick={(event) => {
                      modelProps.onClose3();
                      modelProps.setShowToolbar((old: any) => !old);
                      window.scrollTo(0, document.body.scrollHeight);
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    {
                      modelProps.showToolbar  && <CloseIcon
                        width="24px"
                        height="24px"
                        className="text-xl  text-[#4E5969] dark:text-[#4E5969] cursor-pointer"
                      />
                    }
                    {
                      !modelProps.showToolbar  && <GgAdd
                        width="24px"
                        height="24px"
                        className="text-xl  text-[#4E5969] dark:text-[#4E5969] cursor-pointer"
                      />
                    }
                  </div>
                )}
              </div>
            </div>
            {/*底部菜单*/}
            <BottomMenu />
            {/*底部表情*/}
            <BottomPhiz />
          </div>
        </div>
      </div>

    </div>
  );
};
